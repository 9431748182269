import { Dialog, DialogTitle, Button, DialogContent, Box, Typography, Grid, makeStyles, IconButton } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { RichGrid } from "../../../components/table/RichGrid.component";
import { RowHandlers } from "../../../components/table/Table";
import { busFilledPassengersTableProvider } from "../../../reservations/reservationGrid.columns";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { useDomainPath } from "../../../auth/auth.context";
import { post } from "../../../fetch";
import { CheckBusReplace } from "../../../reservations/create/components/checkBusReplace.component";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

interface Props {
  reservations: CRM.ReservationDetailed[];
  busName: string;
  driverName: string;
  dialogOpen: boolean;
  domains: CRM.Dictionary<string>;
  includingSpotOccurrence: CRM.SpotTimingsOccurence;
  spot: CRM.ActivitySpotDocument | undefined;
  busesFilled: CRM.FilledBusTravel[];
  direction: CRM.BusTravelDirection;
  allSpots: CRM.ActivitySpotDocument[];
  onUpdate: () => void;
  dialogClose: () => void;
}


export const ViewPassengersDialog: React.FC<Props> = (
  {
    reservations,
    busName,
    driverName,
    dialogOpen,
    domains,
    includingSpotOccurrence,
    spot,
    busesFilled,
    direction,
    allSpots,
    onUpdate,
    dialogClose
  }) => {

  const reservationPath = useDomainPath(`/reservation`);
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const [selectedReservation, setSelectedReservation] = useState<CRM.ReservationDocument>();
  const [actionButton, setActionButton] = useState<boolean>(false);

  const onSelectReservation = useCallback((reservation: CRM.ReservationDetailed) => {
    setSelectedReservation(
      {
        ...reservation as unknown as CRM.ReservationDocument, 
        activity:reservation.activity._id
      }
    );
    setActionButton(true);
  },[]);

  const printList = useCallback(() => {
    if (reservations.length === 0) {
      return;
    }
    post<CRM.BusPassengersHtml>(reservationPath+'/busPassengersHtml', {
      busName: busName,
      driverName: driverName,
      spotDisplayName: spot?.displayName || 'Inconnu',
      reservations: reservations, 
      departureTime: includingSpotOccurrence,
    }).then(result => {
      var win = window.open('', '_blank');
      if(win) {
        win.document.body.innerHTML = '<body onload=`window.print()`><div style="margin-top:20px;">'+result+'</div></body>';
        win.print();
      }
    });
  }, [
    busName,
    spot,
    reservations,
    includingSpotOccurrence,
    reservationPath
  ]);

  return (
    <Dialog
    onClose={dialogClose}
    open={dialogOpen}
    maxWidth={'lg'}
    fullWidth={true}
    id='timeSlotsList'
  >
      <DialogTitle>
      <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            Liste des passagers: {busName}
            &nbsp;
            <Button onClick={e => printList()/*PrintElem('timeSlotsList')*/}>
              <PrintIcon />
            </Button>
          </Box>
        <Box>
          <IconButton onClick={dialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box style={{ minHeight: '70vh', overflow: 'hidden' }} className='App'>
        <Grid container spacing={2} xs={12} >
          <Grid item>
            <Typography variant="subtitle1">
              RESERVATIONS
            </Typography>
          </Grid>
        </Grid>
        <RichGrid
          style={{
            paddingRight: '10px', //compensate for scrollbar width
          }}
          columns={busFilledPassengersTableProvider(domains, onSelectReservation).columns}
          rows={reservations}
          renderRow={reservation => {
            return (
              <div className='Grid-row'>
                <Grid
                  container
                  className={classesgrid.root}
                  spacing={1}
                >
                  {busFilledPassengersTableProvider(domains, onSelectReservation).columns.map(column => {
                    const Cell = column.Cell;
                    return (
                      <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                        {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                        {column.renderCell &&
                          column.renderCell(reservation, {} as RowHandlers)}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          }}
        />
      </Box>
    </DialogContent>
    {selectedReservation &&
      <CheckBusReplace
        busesFilled={busesFilled}
        reservation={selectedReservation as unknown as Partial<CRM.ReservationDocument>}
        spotTimingOccurence={includingSpotOccurrence}
        allSpots={allSpots}
        spotId={spot?._id || ''}
        direction={direction}
        onUpdate={onUpdate}
        actionButton={actionButton}
        setActionButton={setActionButton}
      />
    }
    {/*<DialogActions>
      <Button onClick={dialogClose}>Fermer</Button>
    </DialogActions>*/}
  </Dialog>
  );

}
