import React from "react";
import { Box, Radio, RadioGroup, Typography } from "@material-ui/core";
import { BusStatusLabel } from "../../components/labels/busStatusLabel.component";

interface BusStatusProps {
  busStatus?: CRM.BusStatus
}

export const BusStatus: React.FC<BusStatusProps> = ({
  busStatus
}) => {
  const status: CRM.BusStatus[] = ['ready', 'in-rotation', 'deposit'];

  return (
  <Box>
    <Typography>
      <b>Statut Actuel :</b>
    </Typography>
    <RadioGroup
      aria-label="Bus Status"
      value={busStatus || "ready"}
    >
      {
        status.map(status => {
          //console.log(status, bus?.status)
          return (
            <Typography>
            <Radio
              color="secondary"
              value={status}
            />
            &nbsp;{BusStatusLabel(status)}
            </Typography>
          )
        })
      }
    </RadioGroup>
  </Box>
  );
}