import React from 'react';
import { Chip } from "@material-ui/core";


const statusLabel: Record<CRM.BusStatus, React.ReactElement> = {
  "ready": (
    <Chip
      size="medium"
      label="PRET"
      color="secondary"
      className={'bold bgGreen'}
      style={{fontSize: '0.6rem'}}
    />
  ),
  "in-rotation": (
    <Chip
      size="medium"
      label="EN ROTATION"
      color="secondary"
      className={'bold bgOrange'}
      style={{fontSize: '0.6rem'}}
    />
  ),
  "deposit": (
    <Chip
      size="medium"
      label="EN DEPOT"
      color="secondary"
      className={'bold bgRed'}
      style={{fontSize: '0.6rem'}}
    />
  )
}

export function BusStatusLabel(_status: CRM.BusStatus) {
  return statusLabel[_status];
}