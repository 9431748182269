import dayjs from 'dayjs';
import React from 'react';
import { sortBy } from 'lodash';
import { Box, GridSize, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningIcon from '@material-ui/icons/Warning';
//import VisibilityIcon from '@material-ui/icons/Visibility';
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import BlockIcon from '@material-ui/icons/Block';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import {
  CellProps,
  Column,
  compareDate,
  compareNumber,
  Table,
} from '../components/table/Table';
import { ReservationPeopleColumn, ReservationArrivalPeopleColumn } from '../components/column/ReservationPeopleColumn.component';
import { PaymentStatusBadge } from './components/paymentStatusBadge.component';
import {
  canoeEquipementOf,
  countBookingPeople,
  countBookingChildrenUnderSeven,
  paymentStatus,
  departureCapacityColor,
  chronologicalNumberStatus,
  // translateStatus,
} from '@crm/utils';
import { CompactCheckbox } from '../components/fields/CompactCheckbox.component';
import { PositiveNumberField } from '../components/fields/PositiveNumberField.component';
import { EditableDepartureCell } from './components/EditableDepartureCell.component';
import { EditableArrivalCell } from './components/EditableArrivalCell.component';
import { sumPaymentReceipts } from '@crm/utils';
import { lastReceipt } from '@crm/utils';
import { sumReceiptsMethod } from '../sales/sumReceiptsMethod';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { AuthContext } from '../auth/auth.context';
import { ContractStatusBadge } from './components/contractStatusBadge.component';
//import AlarmIcon from '@material-ui/icons/Alarm';

const EquipmentUpdater: React.FC<
  CellProps<CRM.ReservationDetailed> & { equipmentField: string }
> = props => (
  <PositiveNumberField
    inputProps={{
      style: { width: '55px' },
    }}
    variant="standard"
    value={canoeEquipementOf(props.equipmentField as any, props.value.options)}
    onClick={event => event.stopPropagation()}
    onChange={event => {
      props.handlers.dispatch &&
        props.handlers.dispatch({
          type: 'update-deep-field',
          payload: {
            id: props.value._id,
            field: 'options',
            original: props.value.options,
            update: {
              equipments: {
                [props.equipmentField]: Number(event.target.value),
              },
            },
          },
        });
    }}
  />
);

/*const refColumn: Column<CRM.ReservationDetailed> = {
  key: 'ref',
  label: <span>N° résa</span>,
  order: 1,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareNumber(a.refNumber, b.refNumber),
  renderCell: value => {
    return <span>#{value.refNumber}</span>;
  },
};*/

function customerColumn(_xs?:GridSize): Column<CRM.ReservationDetailed> { 
  return {
  key: 'customer',
  label: 'Nom',
  order: 2,
  xs: _xs ? _xs : 1,
  sortable: true,
  compare: (a, b) => (a.customer?.name || '').localeCompare(b.customer.name),
  renderCell: value => (
    <span>
      {value.customer.name} {value.customer.firstname}
    </span>
  ),
}};
const customerEmptyColumn: Column<CRM.ReservationDetailed> = {
  key: 'customer',
  label: 'Nom',
  order: 2,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
    </span>
  ),
};
function peopleColumn(_xs?: GridSize): Column<CRM.ReservationDetailed> {
  return {
    key: 'people',
    label: 'Pers',
    order: 3,
    xs: _xs? _xs : 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(countBookingPeople(a.people), countBookingPeople(b.people)),
    renderCell: value => <ReservationPeopleColumn
      people={value.people}
      instructors={value.options.instructors}
      haveInstructor={(value.options.instructors && value.options.instructors.length > 0) ? true : false}
      countInstructors={false}
    />,
  }
};
function peopleColumnWithoutInstructors(_xs?: GridSize): Column<CRM.ReservationDetailed> {
  return {
    key: 'people',
    label: 'Pers',
    order: 3,
    xs: _xs? _xs : 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(countBookingPeople(a.people), countBookingPeople(b.people)),
    renderCell: value => <ReservationPeopleColumn
      people={value.people}
      instructors={undefined}
      haveInstructor={false}
      countInstructors={false}
      _xs={12}
    />,
  }
};
const peopleColumnWithInstructors: Column<CRM.ReservationDetailed> = {
  key: 'people',
  label: 'Pers',
  order: 3,
  xs: 1,
  sortable: true,
  compare: (a, b) =>
    compareNumber(countBookingPeople(a.people, a.options.instructors), countBookingPeople(b.people, b.options.instructors)),
  renderCell: value => <ReservationPeopleColumn 
  people={value.people} 
  instructors={value.options.instructors} 
  haveInstructor={(value.options.instructors && value.options.instructors.length > 0) ? true : false}
  countInstructors={true}
  />,
};

/*const instructorsColumn: Column<CRM.ReservationDetailed> = {
  key: 'instructors',
  label: 'Moniteur(s)',
  order: 4,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {value.options.instructors &&
        (
          value.options.instructors?.map((instructor) => instructor).join(', ')
      )}
    </span>
  ),
};*/

function peopleColumnFromTimeSlot(timeSlotReservationsViewDialog: (activity?: string, id?: string) => void): Column<any> {
  return {
    key: 'people',
    label: 'Pers',
    order: 3,
    xs: 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(countBookingPeople(a.people, a.options.instructors), countBookingPeople(b.people, b.options.instructors)),
    renderCell: value => <Box onClick={
      e => {
        e.stopPropagation();
        const activityId = typeof value.activity === 'string' ? value.activity : value.activity._id;
        timeSlotReservationsViewDialog(activityId, value.activityTimeSlot)
      }
    }>
      <ReservationPeopleColumn
        people={value.people}
        instructors={value.options.instructors}
        haveInstructor={true}
        countInstructors={true}
      />
    </Box>,
  }
};

function countBookingPeopleAtArrival(people:number, peopleAtArrival: number | null | undefined) {
  console.log(peopleAtArrival)
  const r:number = (peopleAtArrival !== undefined
    && peopleAtArrival !== null
    && peopleAtArrival >= 0) ?
    peopleAtArrival
  : people;
  return r;
}

const peopleWithMonitorColumn: Column<CRM.ReservationDetailed> = {
  key: 'peopleWithMonitor',
  label: 'Nb Enfants avec Moniteur',
  order: 4,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
      {countBookingChildrenUnderSeven(value.pricing, value.people)}
    </span>
  ),
};

function peopleArrivalColumn(_xs?: GridSize): Column<CRM.ReservationDetailed> {
  return {
  key: 'people',
  label: 'Pers R',
  order: 3,
  xs: _xs? _xs : 1,
  sortable: true,
  compare: (resa, resb) =>
    compareNumber(
      countBookingPeopleAtArrival(countBookingPeople(resa.people, resa.options.instructors),
        resa.options?.peopleAtArrivalCount),
        countBookingPeopleAtArrival(countBookingPeople(resb.people,resb.options.instructors),
        resb.options?.peopleAtArrivalCount)
      ),
  renderCell: value => <ReservationArrivalPeopleColumn
  people={value.people}
  instructors= {value.options.instructors}
  peopleAtArrival={value.options?.peopleAtArrivalCount}
  haveInstructor={(value.options?.instructors && value.options.instructors.length > 0) ? true : false}
  />,}
};

function peopleArrivalColumnFromTimeSlot(timeSlotReservationsViewDialog: (activity?: string, id?: string) => void): Column<any> {
  return {
    key: 'people',
    label: 'Pers R',
    order: 3,
    xs: 1,
    sortable: true,
    compare: (resa, resb) =>
      compareNumber(
        countBookingPeopleAtArrival(
          countBookingPeople(resa.people, resa.options.instructors),
          (resa.options?.peopleAtArrivalCount && resa.options?.peopleAtArrivalCount > 0) ?
            resa.options?.peopleAtArrivalCount + 1 : undefined
        ),
        countBookingPeopleAtArrival(countBookingPeople(resb.people, resb.options.instructors),
          (resb.options?.peopleAtArrivalCount && resb.options?.peopleAtArrivalCount > 0) ?
            resb.options?.peopleAtArrivalCount + 1 : undefined
        )
      ),
    renderCell: value => <Box onClick={
      e => {
        e.stopPropagation();
        const activityId = typeof value.activity === 'string' ? value.activity : value.activity._id;
        timeSlotReservationsViewDialog(activityId, value.activityTimeSlot)
      }
    }>
      <ReservationArrivalPeopleColumn
        people={value.people}
        instructors={value.options.instructors}
        peopleAtArrival={
          countBookingPeopleAtArrival(countBookingPeople(value.people, value.options.instructors),
            (value.options?.peopleAtArrivalCount && value.options?.peopleAtArrivalCount > 0) ?
              value.options?.peopleAtArrivalCount + 1 : undefined // adds instructor for grouped reservations
          )
        }
        haveInstructor={true}
      />
    </Box>,
  }
};


const activityColumn: Column<CRM.ReservationDetailed> = {
  key: 'activity',
  label: 'Activité',
  order: 4,
  xs: 1,
  sortable: true,
  compare: (a, b) =>
    (a.activity.displayName || '').localeCompare(b.activity.displayName),
  renderCell: value => <span>{value.activity.displayName}</span>,
};
const k1Column: Column<CRM.ReservationDetailed> = {
  key: 'k1',
  label: 'K1',
  order: 5,
  xs: 1,
  sortable: false,
  Cell: props => <span>{canoeEquipementOf('k1', props.value.options)}</span>,
};
const c2c3Column: Column<CRM.ReservationDetailed> = {
  key: 'c2',
  label: 'C2 et C3',
  order: 6,
  xs: 1,
  sortable: false,
  Cell: props => <div><span>{canoeEquipementOf('c2', props.value.options)}</span> <span>{canoeEquipementOf('c3', props.value.options)}</span></div>,
};
const c2Column: Column<CRM.ReservationDetailed> = {
  key: 'c2',
  label: 'C2',
  order: 6,
  xs: 1,
  sortable: false,
  Cell: props => <span>{canoeEquipementOf('c2', props.value.options)}</span>,
};
const c3Column: Column<CRM.ReservationDetailed> = {
  key: 'c3',
  label: 'C3',
  order: 7,
  xs: 1,
  sortable: false,
  Cell: props => <span>{canoeEquipementOf('c3', props.value.options)}</span>,
};
const editableK1Column: Column<CRM.ReservationDetailed> = {
  key: 'k1',
  label: 'K1',
  order: 5,
  xs: 1,
  sortable: false,
  Cell: props => <EquipmentUpdater equipmentField={'k1'} {...props} />,
};
const editableC2C3Column: Column<CRM.ReservationDetailed> = {
  key: 'c2c3',
  label: 'C2 et C3',
  order: 6,
  xs: 1,
  sortable: false,
  Cell: props => <div><EquipmentUpdater equipmentField={'c2'} {...props} /> <EquipmentUpdater equipmentField={'c3'} {...props} /></div>,
};
const dateColumn: Column<CRM.ReservationDetailed> = {
  key: 'date',
  label: 'Date',
  order: 8,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.activityDate, b.activityDate),
  renderCell: value => (
    <span>{dayjs(value.activityDate).format('DD/MM/YY')}</span>
  ),
};
const fullDateColumn: Column<CRM.ReservationDetailed> = {
  key: 'fullDate',
  label: 'Départ',
  order: 8,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.activityDate, b.activityDate),
  renderCell: value => (
    <span>{dayjs(value.activityDate).format('DD/MM/YY HH:mm')}</span>
  ),
};
function departureHourColumn(buses?: CRM.EntityName[]): Column<CRM.ReservationDetailed> {
  return {
  key: 'departureTime',
  label: 'Départ',
  order: 9,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.activityDate, b.activityDate),
  renderCell: value => <span>
    {dayjs(value.activityDate).format('HH:mm')}
    &nbsp;
    { buses && value.checkInBusId && 
      <DirectionsBusIcon titleAccess={
        buses.find(b=>b._id.includes(value.checkInBusId || ''))?.displayName || 'Bus Introuvable (supprimé?)'
      }/>
    }
    { buses && !value.checkInBusId && (chronologicalNumberStatus(value.status) > chronologicalNumberStatus("booked")) &&
      <WarningIcon titleAccess={
          'aucun bus disponible'
      }/>
    }
  </span>,
  }
};
const startedHourColumn: Column<CRM.ReservationDetailed> = {
  key: 'startedTime',
  label: 'Check-In',
  order: 9,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.startedDate, b.startedDate),
  renderCell: value => <span>{dayjs(value.startedDate).format('HH:mm')}</span>,
};
const editableDepartureHourColumn: Column<CRM.ReservationDetailed> = {
  key: 'departureTime',
  label: 'Départ',
  order: 9,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.activityDate, b.activityDate),
  Cell: props => (
    <EditableDepartureCell
      activityId={props.value.activity._id}
      activityDate={props.value.activityDate && dayjs(props.value.activityDate)}
      onChange={newActivityDate => {
        props.handlers.dispatch &&
          props.handlers.dispatch({
            type: 'update-field',
            payload: {
              id: props.value._id,
              field: 'activityDate',
              update: newActivityDate,
            },
          });
      }}
    />
  ),
};
function arrivalHourColumn(buses?: CRM.EntityName[]): Column<CRM.ReservationDetailed> {
  return {
    key: 'arrivalTime',
    label: 'Arrivée',
    order: 10,
    xs: 1,
    sortable: true,
    compare: (a, b) => compareDate(a.activityArrivalDate, b.activityArrivalDate),
    renderCell: value => (
      <span>
        {value.activityArrivalDate &&
          dayjs(value.activityArrivalDate).format('HH:mm')}
        &nbsp;
        {buses && value.checkOutBusId &&
          <DirectionsBusIcon titleAccess={
            buses.find(b => b._id.includes(value.checkOutBusId || ''))?.displayName || 'bus introuvable'
          } />
        }
      </span>
    ),
  }
};
const editableArrivalHourColumn: Column<CRM.ReservationDetailed> = {
  key: 'arrivalTime',
  label: 'Arrivée',
  order: 10,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.activityDate, b.activityDate),
  Cell: props => (
    <EditableArrivalCell
      activityId={props.value.activity._id}
      activityOverDays={props.value.activity.overDays}
      departureDate={dayjs(props.value.activityDate)}
      arrivalDate={
        props.value.activityArrivalDate &&
        dayjs(props.value.activityArrivalDate)
      }
      onChange={newArrivalDate => {
        props.handlers.dispatch &&
          props.handlers.dispatch({
            type: 'update-field',
            payload: {
              id: props.value._id,
              field: 'activityArrivalDate',
              update: newArrivalDate,
            },
          });
      }}
    />
  ),
};

function sellerGroupColumnFromId(groups: any,domains: CRM.EntityName[], domainConfig: CRM.DomainConfig | undefined): Column<CRM.ReservationDetailed> {

  return {
    key: 'sellerGroup',
    label: 'Point de Vente',
    order: 11,
    xs: 1,
    sortable: true,
    compare: (a, b) =>
      (groups[a.sellerUserGroup] || '').localeCompare(groups[b.sellerUserGroup]),
    Cell: props => {
      const group: string | undefined = groups[props.value.pricingGroupId] ? groups[props.value.pricingGroupId] : undefined;
      const domain: CRM.EntityName | undefined = domains.find(d => d._id.valueOf() === (props.value.domain.valueOf()));
      const domainName: string | undefined = (
        domain &&
        domainConfig && 
        domain._id.valueOf() !== domainConfig.id
        ) ? 
        domain.displayName : undefined;
      //console.log(props.value)

      if (props.value.bookingId) {
        return (
          <Box
            display="flex"
            alignItems="end"
            justifyContent={'space-between'}
            title={`Résa web n°${props.value.bookingId}
            ${domainName ? ' Domaine : '+domainName
              : ''}
            ${group ? ' Point de vente : '+group
              : ''}`
            }
          >
            {!group && !domainName && <AlternateEmailIcon opacity={0.5} />}
            {(group || domainName) && <i><AlternateEmailIcon opacity={0.5} />{ group ? ': '+group : (domainName ? domainName : '')}</i>}
          </Box>
        );
      }
      return (
        <span>{groups[props.value.sellerUserGroup]}</span>
      )},
  };
};

const paymentStatusColumn: Column<CRM.ReservationDetailed> = {
  key: 'payment',
  label: 'Réglement',
  order: 11,
  xs: 1,
  sortable: true,
  compare: (a, b) => {
    const aStatus = paymentStatus(a.payment);
    const bStatus = paymentStatus(b.payment);

    if (aStatus === bStatus) return 0;
    if (aStatus === 'paid') return 1;
    return -1;
  },
  renderCell: value => (
  <div>
  <span style={{float:"left", marginRight:"5px"}}>
    <PaymentStatusBadge payment={value.payment}/> 
  </span>
  </div>
  ),
};

const contractStatusColumn: Column<CRM.ReservationDetailed> = {
  key: 'contract',
  label: 'Status Contrat',
  order: 11,
  xs: 1,
  sortable: true,
  compare: (a, b) => {
    const aStatus = paymentStatus(a.payment);
    const bStatus = paymentStatus(b.payment);

    if (aStatus === bStatus) return 0;
    if (aStatus === 'paid') return 1;
    return -1;
  },
  renderCell: value => (
  <div>
  <span>
  <ContractStatusBadge envelope={value.documents?.docusignEnvelope} />
    {/* value.documents?.docusignEnvelope?.status === 'sent' &&
    <b title='E-CONTRAT EN ATTENTE DE SIGNATURE'>
      <AlarmIcon style={{fontSize:"32px",color:'#f2eb16'}}/>
    </b>
  */}
  </span>
  </div>
  ),
};

function checkInByCallbackParameterColumn(
  AddRemoveCheckInReservation: (Id: string, checked: boolean) => void,
  checkInReservations: Partial<CRM.ReservationDetailed>[]
  ): Column<CRM.ReservationDetailed> {
  return {
    key: 'checkIn',
    label: 'Check-in',
    order: 12,
    xs: 1,
    sortable: false,
    Cell: props => {
      if (
        paymentStatus(props.value.payment) !== 'paid' || 
      (props.value.status !== 'booked' && props.value.status !== 'started')
      ) {
        return null;
      }
      const checkInValue = checkInReservations.find(r => r._id === props.value._id)?.status;
      return(
      <Box
        display="flex"
        justifyContent={'center'}
      >
        <CompactCheckbox
          color="secondary"
          onChange={event => {
            event.stopPropagation();
            const checked = (event.target as any).checked;
            console.log(checked, props.value.status, checkInReservations)
            AddRemoveCheckInReservation(props.value._id, checked as boolean);
          }}
          checked={
            checkInValue !== undefined ? (checkInValue === 'started' ? true : false) :
            props.value.status === 'started' ? true : false
          }
        />
      </Box>)},
  }
}

const checkInColumn: Column<CRM.ReservationDetailed> = { 
  key: 'checkIn',
  label: 'Check-in',
  order: 12,
  xs: 1,
  sortable: false,
  Cell: props => {
    if (paymentStatus(props.value.payment) !== 'paid') {
      return null;
    }

    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CompactCheckbox
          color="secondary"
          onClick={event => {
            event.stopPropagation();
            const checked = (event.target as any).checked;
            //console.log(checked, props.value.status)
            props.handlers.dispatch &&
              props.handlers.dispatch({
                type: 'update-field',
                payload: {
                  id: props.value._id,
                  field: 'status',
                  update: checked ? 'started' : 'booked',
                },
            });
            props.handlers.dispatch &&
            props.handlers.dispatch({
              type: 'update-field',
              payload: {
                id: props.value._id,
                field: 'checkInBusId',
                update: checked ? "update" : undefined,
              },
          });
          }}
          checked={props.value.status === 'started'}
        />
      </Box>
    );
  },
};
const checkDoneColumn: Column<CRM.ReservationDetailed> = {
  key: 'checkDone',
  label: 'Terminer',
  order: 12,
  xs: 1,
  sortable: false,
  Cell: props => {
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CompactCheckbox
          color="secondary"
          onClick={event => {
            event.stopPropagation();
            const checked = (event.target as any).checked;
            props.handlers.dispatch &&
              props.handlers.dispatch({
                type: 'update-field',
                payload: {
                  id: props.value._id,
                  field: 'status',
                  update: checked ? 'done' : 'in-activity',
                },
              });
          }}
          checked={props.value.status === 'done'}
        />
      </Box>
    );
  },
};

function checkColumnForDriverBusTravel(checkEvent: (reservationId: string, driverCheckStatus: CRM.ReservationDriverCheckStatus) => void) : Column<CRM.ReservationDetailed> {
  return {
    key: 'check',
    label: 'Check',
    order: 12,
    xs: 1,
    sortable: false,
    Cell: props => {
      return (
        <Box display={'flex'} justifyContent={'center'}>
          <CompactCheckbox
            color="secondary"
            onClick={event => {
              event.stopPropagation();
              const checked = (event.target as any).checked;
              checked ? checkEvent(props.value._id, 'checked') : 
                checkEvent(props.value._id, 'not-checked')
            }}
            checked={props.value.driverCheckStatus === 'checked'}
          />
        </Box>
      );
    },
  }
}

function checkDoneColumnForBusTravel(checkOutEvent: (reservationId: string, status: CRM.ReservationStatus) => void) : Column<CRM.ReservationDetailed> {
  return {
    key: 'checkDone',
    label: 'CheckOut',
    order: 12,
    xs: 1,
    sortable: false,
    Cell: props => {
      return (
        <Box display={'flex'} justifyContent={'center'}>
          <CompactCheckbox
            color="secondary"
            onClick={event => {
              event.stopPropagation();
              const checked = (event.target as any).checked;
              checked ? checkOutEvent(props.value._id, 'done') : 
                checkOutEvent(props.value._id, 'in-activity')
            }}
            checked={props.value.status === 'done'}
          />
        </Box>
      );
    },
  }
}

const checkToRemoveColumn: Column<CRM.ReservationDetailed> = {
  key: 'checkToRemove',
  label: 'Suppr Définitivement',
  order: 12,
  xs: 1,
  sortable: false,
  Cell: props => {
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CompactCheckbox
          color="secondary"
          onClick={event => {
            event.stopPropagation();
            const checked = (event.target as any).checked;
            props.handlers.dispatch &&
              props.handlers.dispatch({
                type: 'update-field',
                payload: {
                  id: props.value._id,
                  field: 'status',
                  update: checked ? 'wait-to-deleted' : "no-changes",
                },
              });
          }}
          checked={props.value.status === 'wait-to-deleted'}
        />
      </Box>
    );
  },
};

const createdDateColumn: Column<CRM.ReservationDetailed> = {
  key: 'createdDate',
  label: 'Date de création',
  order: 12,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.transactionDate, b.transactionDate),
  renderCell: value => (
    <span>
      {value.transactionDate &&
        dayjs(value.transactionDate).format('DD/MM/YY')}
    </span>
  ),
};

const expirationDateColumn: Column<CRM.ReservationDetailed> = {
  key: 'expirationDate',
  label: 'Date d\'expiration',
  order: 12,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
      {value.transactionDate &&
        dayjs(value.transactionDate).add(2, 'year').format('DD/MM/YY')}
    </span>
  ),
};

const deletedDateColumn: Column<CRM.ReservationDetailed> = {
  key: 'deletedDate',
  label: 'Date Suppr',
  order: 12,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.deletedDate, b.deletedDate),
  renderCell: value => (
    <span>{value.deletedDate ? dayjs(value.deletedDate).format('DD/MM/YY') : ''}</span>
  ),
};

const deletedByColumn: Column<CRM.ReservationDetailed> = {
  key: 'deletedBy',
  label: 'Suppr Par',
  order: 12,
  xs: 1,
  sortable: true,
  compare: (a, b) => (a.deletedByUser || '').localeCompare(b.deletedByUser || ''),
  renderCell: value => (
    <span>{value.deletedByUser || ''}</span>
  ),
};

const lastRefundDateColumn: Column<CRM.ReservationDetailed> = {
  key: 'lastrefundDate',
  label: 'Remb. le',
  order: 12,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
      {value.payment?.optionalStatus === 'refunded' &&
        (value.payment?.refunds?.slice(-1)[0]?.date ? dayjs(value.payment?.refunds?.slice(-1)[0]?.date).format('DD/MM/YY') : '')}
    </span>
  ),
};

function selectColumn(selectedIds: string[], onChange: any): Column<CRM.ReservationDetailed> {
  return {
    key: 'select',
    label: 'Sélection',
    order: 5,
    xs: 1,
    sortable: false,
    Cell: props => {
      return (
        <Box display={'flex'} justifyContent={'center'}>
          <CompactCheckbox
            color="secondary"
            onClick={(event) => { event.stopPropagation(); onChange && onChange(props.value)}}
            checked={selectedIds.includes(props.value._id)}
          />
        </Box>
      )
    },
  }
};

function domainColumnFromId(domains: any, _xs?:GridSize, _order?:number): Column<CRM.ReservationDetailed> {
  return {
    key: 'domain',
    label: 'Location',
    order: _order ? _order : 4,
    xs: _xs ? _xs : 1,
    sortable: true,
    compare: (a, b) =>
      (domains[a.domain] || '').localeCompare(domains[b.domain]),
    renderCell: value => <span>{domains[value.domain]}</span>,
  }
};

const busColumn: Column<CRM.ReservationDetailed> = {
  key: 'bus',
  label: 'Bus',
  order: 12,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span></span>
  ),
};

type TableProvider = (
  status: CRM.ReservationStatus | undefined,
  active: CRM.ReservationActive,
  groups: CRM.Dictionary<string>,
  domains: CRM.EntityName[],
  domainConfig: CRM.DomainConfig | undefined,
  departureBuses: CRM.EntityName[],
  arrivalBuses: CRM.EntityName[],
  isAdmin?: boolean | undefined
) => Table<CRM.ReservationDetailed>;

type BusFilledPassengersTableProvider = (
  domains: CRM.Dictionary<string>,
  onSelect:(reservation: CRM.ReservationDetailed)=> void
) => Table<CRM.ReservationDetailed>;

type TravelListPassengersTableProvider = (
  domains: CRM.Dictionary<string>,
  direction: CRM.BusTravelDirection,
  checkEvent: (reservationId: string, driverCheckStatus: CRM.ReservationDriverCheckStatus) => void
) => Table<CRM.ReservationDetailed>;

type BusUnFilledPassengersTableProvider = (
  domains: CRM.Dictionary<string>,
  checkOutEvent: (reservationId: string, status: CRM.ReservationStatus) => void
) => Table<CRM.ReservationDetailed>;

type SupervisedTableProvider = (
  groups: CRM.Dictionary<string>,
  domains: CRM.EntityName[],
  domainConfig: CRM.DomainConfig | undefined,
  AddRemoveCheckInReservationId: (Id: string, checked: boolean) => void,
  checkInReservationsIds: Partial<CRM.ReservationDetailed>[]
) => Table<CRM.ReservationDetailed>;

type DepartureTableProvider = (
  reservationsIds: string[],
  domains: CRM.Dictionary<string>,
  callback: any,
  departureBuses: CRM.EntityName[],
  timeSlotReservationsViewDialog?: (activity?: string, id?: string) => void,
) => Table<CRM.ReservationDetailed>;

type ArrivalsTableProvider = (
  domains: CRM.Dictionary<string>,
  timeSlotReservationsViewDialog?: (activity?: string, id?: string) => void,
  ) => Table<CRM.ReservationDetailed>;

type SalesTableProvider = (userGroupId: string | undefined) => Table<CRM.ReservationDetailed>;

type ActivityTimeSlotsTableProvider = (
  domainId: string,
  //reservationsViewDialog: (activity?:string, id?:string) => void,
  toggleSelectActivityTimeSlot: (activityTimeSlot: CRM.ActivityTimeSlotDetailed) => void,
  auth: AuthContext,
  domains: CRM.EntityName[],
  showCreateTimeSlotColumn: boolean,
  deletetimeSlotDialog?: () => void,
) => Table<CRM.ActivityTimeSlotDetailed>;

type AvailableActivityTimeSlotsTableProvider = () => Table<CRM.ActivityTimeSlotDetailed>;

const sortColumns = (
  columns: Column<CRM.ReservationDetailed>[],
): Column<CRM.ReservationDetailed>[] => sortBy(columns, c => c.order);

/*const sortTimeSlotsColumns = (
  columns: Column<CRM.ActivityTimeSlotDetailed>[],
): Column<CRM.ActivityTimeSlotDetailed>[] => sortBy(columns, c => c.order);*/

export const statusColumnsOf: (
  status: CRM.ReservationStatus,
  departureBuses: CRM.EntityName[],
  arrivalBuses: CRM.EntityName[],
) => Column<CRM.ReservationDetailed>[] = (status, departureBuses, arrivalBuses) => {
  if (status === 'booked') {
    return [
      editableDepartureHourColumn,
      editableArrivalHourColumn,
      checkInColumn,
    ];
  }
  if (status === 'started') {
    return [departureHourColumn(departureBuses), arrivalHourColumn(arrivalBuses), checkDoneColumn];
  }
  return [departureHourColumn(departureBuses), arrivalHourColumn(arrivalBuses)];
};

export const statusColumnsOfCanoe: (
  status: CRM.ReservationStatus,
) => Column<CRM.ReservationDetailed>[] = status => {
  if (status === 'booked') {
  return [editableK1Column, editableC2C3Column/*, editableC3Column*/];
  }
  if (status === 'started') {
  return [k1Column, c2c3Column/*, c3Column*/];
  }
return [k1Column, c2c3Column/*, c3Column*/];
};

export const activeColumnsOfTrash: (
  active: CRM.ReservationActive,
  isAdmin: boolean | undefined
) => Column<CRM.ReservationDetailed>[] = (active, isAdmin) => {
  if (active === 0) {
    if(isAdmin !== undefined && isAdmin) {
      return [deletedDateColumn, deletedByColumn, checkToRemoveColumn];
    } else {
      return [deletedDateColumn, deletedByColumn];
    }
  }
  return [];
};

const linkToReservationColumn: Column<CRM.ReservationDetailed> = {
  key: 'linkToReservation',
  label: 'Réservation',
  order: 12,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
      <a 
      //rel='noreferrer'
      style={{textDecoration: 'underline'}} 
      href={"/reservations/"+value._id}
      //target='_blank'
      >Voir</a>
    </span>
  ),
};

const linkToReservationInNewTabColumn: Column<CRM.ReservationDetailed> = {
  key: 'linkToReservationInNewTab',
  label: 'Réservation',
  order: 12,
  xs: 1,
  sortable: false,
  renderCell: value => (
    <span>
      <a 
      rel='noreferrer'
      style={{textDecoration: 'underline'}} 
      href={"/reservations/"+value._id}
      target='_blank'
      >Voir</a>
    </span>
  ),
};

function busSelectColumn(onSelect:(reservation: CRM.ReservationDetailed)=> void): Column<CRM.ReservationDetailed> {
  return {
    key: 'selectBus',
    label: 'Bus',
    order: 11,
    xs: 1,
    sortable: false,
    Cell: props => {
      return (
        <IconButton
            title={'changer de bus'}
            onClick={(e) => {e.stopPropagation();onSelect(props.value)}}
            style={{fontSize: '1.8rem !important', padding:'0rem 0.5rem 0rem'}}
          >
          <FindReplaceIcon />
        </IconButton>
      )
    },
  }
};

export const canoeTableProvider: TableProvider = (status, active, groups, domains, domainConfig, departureBuses, arrivalBuses, isAdmin?) => {
  return {
    columns: active === 0 ? sortColumns([
      //refColumn,
      customerColumn(),
      peopleColumn(),
      activityColumn,
      dateColumn,
      sellerGroupColumnFromId(groups, domains, domainConfig),
      paymentStatusColumn,
      contractStatusColumn,
      ...activeColumnsOfTrash(active, isAdmin),
    ]) :
      (
        !status ? sortColumns([
          //refColumn,
          customerColumn(),
          peopleColumn(),
          activityColumn,
          dateColumn,
          sellerGroupColumnFromId(groups, domains, domainConfig),
          paymentStatusColumn,
          contractStatusColumn
        ]) :
          (
            status === 'gift-voucher' ?
              sortColumns([
                //refColumn,
                customerColumn(),
                peopleColumn(),
                activityColumn,
                dateColumn,
                sellerGroupColumnFromId(groups, domains, domainConfig),
                paymentStatusColumn,
                contractStatusColumn,
                createdDateColumn,
                expirationDateColumn,
                checkToRemoveColumn
              ])
              :
              (
                status === 'cancelled' ?
                  sortColumns([
                    //refColumn,
                    customerColumn(),
                    peopleColumn(),
                    activityColumn,
                    dateColumn,
                    sellerGroupColumnFromId(groups, domains, domainConfig),
                    paymentStatusColumn,
                    contractStatusColumn,
                    createdDateColumn,
                    lastRefundDateColumn,
                    checkToRemoveColumn
                  ])
                  :
                  sortColumns([
                    //refColumn,
                    customerColumn(),
                    peopleColumn(),
                    activityColumn,
                    dateColumn,
                    sellerGroupColumnFromId(groups, domains, domainConfig),
                    paymentStatusColumn,
                    contractStatusColumn,
                    ...statusColumnsOf(status, departureBuses, arrivalBuses),
                    ...statusColumnsOfCanoe(status),
                  ]))
          )
      ),
  };
};

export const supervisedTableProvider: TableProvider = (status, active, groups, domains, domainConfig, departureBuses, arrivalBuses, isAdmin?) => {
  return {
    columns: active === 0 ? sortColumns([
      //refColumn,
      customerColumn(),
      peopleColumn(),
      activityColumn,
      dateColumn,
      sellerGroupColumnFromId(groups, domains, domainConfig),
      paymentStatusColumn,
      contractStatusColumn,
      ...activeColumnsOfTrash(active, isAdmin),
    ]) :
      (
        !status ? sortColumns([
          //refColumn,
          customerColumn(),
          peopleColumn(),
          activityColumn,
          dateColumn,
          sellerGroupColumnFromId(groups, domains, domainConfig),
          paymentStatusColumn,
          contractStatusColumn
        ]) :
          (
            status === 'gift-voucher' ?
              sortColumns([
                //refColumn,
                customerColumn(),
                peopleColumn(),
                activityColumn,
                dateColumn,
                sellerGroupColumnFromId(groups, domains, domainConfig),
                paymentStatusColumn,
                contractStatusColumn,
                createdDateColumn,
                expirationDateColumn,
                checkToRemoveColumn
              ])
              :
              (
                status === 'cancelled' ?
                  sortColumns([
                    //refColumn,
                    customerColumn(),
                    peopleColumn(),
                    activityColumn,
                    dateColumn,
                    sellerGroupColumnFromId(groups, domains, domainConfig),
                    paymentStatusColumn,
                    contractStatusColumn,
                    createdDateColumn,
                    lastRefundDateColumn,
                    checkToRemoveColumn
                  ])

                  :
                  sortColumns([
                    //refColumn,
                    customerColumn(),
                    peopleColumn(),
                    activityColumn,
                    dateColumn,
                    sellerGroupColumnFromId(groups, domains, domainConfig),
                    paymentStatusColumn,
                    contractStatusColumn,
                    ...statusColumnsOf(status, departureBuses, arrivalBuses),
                  ]))
          )
      ),
  };
};

export const travelListPassengersTableProvider: TravelListPassengersTableProvider = (
  domains,
  direction,
  checkEvent
) => {
  return {
    columns: 
    sortColumns([
      domainColumnFromId(domains, 3),
      customerColumn(3),
      direction === "return" ? peopleArrivalColumn(2) : peopleColumn(2),
      checkColumnForDriverBusTravel(checkEvent)
    ]),
  };
};

export const busFilledPassengersTableProvider: BusFilledPassengersTableProvider = (
  domains,
  onSelect
) => {
  return {
    columns: 
    sortColumns([
      domainColumnFromId(domains),
      customerColumn(),
      peopleColumn(),
      c2Column,
      c3Column,
      k1Column,
      busSelectColumn(onSelect),
      linkToReservationInNewTabColumn
    ]),
  };
};

export const busUnFilledPassengersTableProvider: BusUnFilledPassengersTableProvider = (
  domains,
  checkOutEvent
) => {
  return {
    columns: 
    sortColumns([
      domainColumnFromId(domains, 2, 1),
      customerColumn(2),
      peopleColumnWithoutInstructors(2),
      c2Column,
      c3Column,
      k1Column,
      checkDoneColumnForBusTravel(checkOutEvent)
    ]),
  };
};

export const supervisedTimeSlotTableProvider: SupervisedTableProvider = (
  groups,
  domains,
  domainConfig,
  AddRemoveCheckInReservationId: (Id: string, checked: boolean) => void,
  checkInReservationsIds: Partial<CRM.ReservationDetailed>[]
  ) => {
  return {
    columns: 
    sortColumns([
      customerColumn(),
      peopleColumn(),
      peopleWithMonitorColumn,
      paymentStatusColumn,
      sellerGroupColumnFromId(groups, domains, domainConfig),
      linkToReservationColumn,
      checkInByCallbackParameterColumn(AddRemoveCheckInReservationId, checkInReservationsIds)
    ]),
  };
};

export const departureTableProvider: DepartureTableProvider = (
  selectedIds: string[], 
  domains, 
  onChange,
  departureBuses,
  timeSlotReservationsViewDialog?: (activity?: string, id?: string) => void,
  ) => {
  return {
    columns: [
      //dateColumn,
      departureHourColumn(departureBuses),
      startedHourColumn,
      domainColumnFromId(domains),
      timeSlotReservationsViewDialog ? customerEmptyColumn : customerColumn(),
      timeSlotReservationsViewDialog ? peopleColumnFromTimeSlot(timeSlotReservationsViewDialog) : peopleColumnWithInstructors,
      //instructorsColumn,
      c2Column,
      c3Column,
      k1Column,
      //departureHourColumn(),
      selectColumn(selectedIds, onChange)
    ],
  };
};

export const arrivalsTableProvider: ArrivalsTableProvider = (
  domains,
  timeSlotReservationsViewDialog?: (activity?: string, id?: string) => void,
  ) => {
  return {
    columns: [
      dateColumn,
      domainColumnFromId(domains),
      timeSlotReservationsViewDialog ? customerEmptyColumn : customerColumn(),
      timeSlotReservationsViewDialog ? peopleArrivalColumnFromTimeSlot(timeSlotReservationsViewDialog) : peopleArrivalColumn(),
      //instructorsColumn,
      c2Column,
      c3Column,
      k1Column,
      busColumn,
      arrivalHourColumn()
    ],
  };
};

function paymentMethodsColumn(userGroupId: string | undefined): Column<CRM.ReservationDetailed> {
  return {
  key: 'paymentMethods',
  label: (
  <table width={'100%'} style={{textAlign:'center', tableLayout: 'fixed', textOverflow: 'ellipsis'}}><tr>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Esp</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Cb</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Ancv</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Chq</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Vir</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Payp</td>
    <td width={'12.5%'} style={{overflow: 'hidden'}}>Sysp</td>
    <td width={'12.5%' } style={{overflow: 'hidden'}}>Autre</td>
  </tr></table>
  ),
  order: 5,
  xs: 2,
  sortable: false,
  Cell: props => <table width={'100%'} style={{textAlign:'center'}}>
    <tr>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'cash',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'cash',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'card',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'card',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'ancv',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'ancv',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'check',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'check',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'transfer',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'transfer',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'paypal',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'paypal',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'systempay',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'systempay',
        userGroupId
      )+'€' : ' - '}</td>
      <td width={'12.5%'}>{sumReceiptsMethod(
        props.value.payment.receipts,
        'other',
        userGroupId
      ) !== 0 ? sumReceiptsMethod(
        props.value.payment.receipts,
        'other',
        userGroupId
      )+'€' : ' - '}</td>
    </tr>
    </table>,
  }
}
const totalActivityColumn: Column<CRM.ReservationDetailed> = {
  key: 'totalactivity',
  label: 'Total Activité',
  order: 6,
  xs: 1,
  sortable: false,
  Cell: props => <span>{props.value.payment.amountInEuro.toFixed(2)}€</span>,
};
function sumPaymentsColumn(userGroupId: string | undefined): Column<CRM.ReservationDetailed> {
return {
    key: 'sumPayments',
    label: 'Encaissement Activité',
    order: 7,
    xs: 1,
    sortable: false,
    Cell: props => <span>{sumPaymentReceipts(
      props.value.payment,
      userGroupId
    ).toFixed(2)}€</span>
  }
}
function additionalSalesColumn(/*userGroupId*/): Column<CRM.ReservationDetailed> {
  return {
    key: 'additionalSales',
    label: 'Ventes Additionnelles',
    order: 8,
    xs: 1,
    sortable: false,
    Cell: props => <span>0.00€</span>
  }
};
function totalSalesColumn(userGroupId: string | undefined): Column<CRM.ReservationDetailed> {
  return {
    key: 'totalsales',
    label: 'Total Encaissement',
    order: 9,
    xs: 1,
    sortable: false,
    Cell: props => <span>{
      sumPaymentReceipts(
        props.value.payment,
        userGroupId
      ).toFixed(2)
      /*+VentesAdditionnelles*/
    }€</span>
  }
};
const lastCollectOperatorColumn: Column<CRM.ReservationDetailed> = {
  key: 'lastcollectoperator',
  label: 'Op. dernier encaissement',
  order: 10,
  xs: 1,
  sortable: false,
  Cell: props => <span>{lastReceipt(props.value)?.userName || lastReceipt(props.value)?.userId}</span>,
};
const lastCollectHourColumn: Column<CRM.ReservationDetailed> = {
  key: 'lastcollecthour',
  label: 'Heure dernier encaissement',
  order: 12,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(lastReceipt(a)?.date, lastReceipt(b)?.date),
  renderCell: value => (
    <span>
     { lastReceipt(value)?.date ? dayjs(lastReceipt(value)?.date).format('DD/MM/YY HH:mm') : 'Pas d\'encaissement'}
    </span>
  ),
};
/*const reservationStatusColumn: Column<CRM.ReservationDetailed> = {
  key: 'reservationstatus',
  label: 'Statut',
  order: 12,
  xs: 1,
  sortable: true,
  compare: (a, b) => (translateStatus(a.status) || '').localeCompare(translateStatus(b.status)),
  renderCell: value => (
    <span>
     {translateStatus(value.status)}
    </span>
  ),
};*/

export const salesTableProvider: SalesTableProvider = (userGroupId) => {
  return {
    columns: [
      customerColumn(),
      peopleColumn(),
      fullDateColumn,
      activityColumn,
      paymentMethodsColumn(userGroupId), //4 columns in 1
      totalActivityColumn,
      sumPaymentsColumn(userGroupId),
      additionalSalesColumn(/*userGroupId*/),
      totalSalesColumn(userGroupId),
      lastCollectOperatorColumn,
      lastCollectHourColumn,
      // reservationStatusColumn
    ],
  };
}

// Columns for timeSlots
const timeSlotActivityColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'activity',
  label: 'Activité',
  order: 1,
  xs: 2,
  sortable: true,
  compare: (a, b) =>
    (a.activity.displayName || '').localeCompare(b.activity.displayName),
  renderCell: value => <span>{value.activity.displayName}</span>,
};
function timeSlotDomainColumn(domains: CRM.EntityName[]): Column<CRM.ActivityTimeSlotDetailed> {
  return {
    key: 'timeSlotDomain',
    label: 'Propriétaire',
    order: 2,
    xs: 1,
    sortable: false,
    /*compare: (a, b) =>
    (a.domain || '').localeCompare(b.domain),*/
    renderCell: value => 
    <Box 
    display="flex" 
    alignItems="end"
    >
      {domains.find(d => d._id.valueOf() === value.domain?.valueOf())?.displayName || 'Non Identifiable'}
    </Box>,
  }
};
const timeslotDateColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'date',
  label: 'Date',
  order: 3,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.timeSlotDeparture, b.timeSlotDeparture),
  renderCell: value => (
    <span>{dayjs(value.timeSlotDeparture).format('dddd DD/MM')}</span>
  ),
};
const timeSlotDepartureHourColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'departureTime',
  label: 'Heure départ',
  order: 4,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.timeSlotDeparture, b.timeSlotDeparture),
  renderCell: value => <span>{dayjs(value.timeSlotDeparture).format('HH:mm')}</span>,
};
const timeSlotArrivalHourColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'arrivalTime',
  label: 'Heure retour',
  order: 5,
  xs: 1,
  sortable: true,
  compare: (a, b) => compareDate(a.timeSlotArrival, b.timeSlotArrival),
  renderCell: value => <span>{dayjs(value.timeSlotArrival).format('HH:mm')}</span>,
};
function timeSlotPeopleColumn(): Column<CRM.ActivityTimeSlotDetailed> {
  return {
    key: 'people',
    label: 'Nb participants',
    order: 6,
    xs: 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(a.bookedPeople | 0, b.bookedPeople | 0),
    renderCell: value => 
    <Box 
    display="flex" 
    alignItems="end"
    style={{color:departureCapacityColor({currentPeople: value.bookedPeople, maxPeople: value.maxPeople})}}
    >
      {(value.bookedPeople | 0) + '/' + value.maxPeople}&nbsp;
    </Box>,
  }
}
function timeSlotPeopleWithMonitorColumn(): Column<CRM.ActivityTimeSlotDetailed> {
  return {
    key: 'peopleWithMonitor',
    label: 'Nb avec Moniteur',
    order: 7,
    xs: 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(a.bookedPeopleWithMonitor | 0, b.bookedPeopleWithMonitor | 0),
    renderCell: value => <Box 
    display="flex" 
    alignItems="end"
    style={{color:departureCapacityColor({currentPeople: value.bookedPeopleWithMonitor, maxPeople: value.maxPeopleWithMonitor})}}
    >
      {(value.bookedPeopleWithMonitor | 0) + '/' + value.maxPeopleWithMonitor}&nbsp;
    </Box>,
  }
}
const timeSlotMonitorColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'monitor',
  label: 'Moniteur',
  order: 8,
  xs: 1,
  sortable: true,
  compare: (a, b) =>
    (a.monitor || '').localeCompare(b.monitor),
  renderCell: value => <span>{value.monitor}</span>,
};

const timeSlotAvailabilityColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'availability',
  label: 'Disponibilité',
  order: 9,
  xs: 1,
  sortable: false,
  renderCell: value => <span style={{color: 'white', fontWeight:'bold'}}>{
    (((value.bookedPeople) >= (value.maxPeople-1)) || value.closeReservations === true) ? //-1 is place for monitor
    <label style={{backgroundColor:'#FF2323',borderRadius:'50%', padding:'1rem'}}>Complet</label> : 
    <label style={{backgroundColor:'#6BCE34',borderRadius:'50%', padding:'0.8rem'}}>Disponible</label>
  }</span>,
};

/*function timeSlotShowReservationsColumn(reservationsViewDialog: (activity?:string, id?:string) => void): Column<CRM.ActivityTimeSlotDetailed> {
  return {
  key: 'showreservations',
  label: 'Clients',
  order: 8,
  xs: 1,
  sortable: false,
  renderCell: value => <span>
    {value.bookedPeople > 0 &&
      <IconButton style={{ textDecoration: 'underline' }} onClick={(e) => { reservationsViewDialog(value.activity._id, value._id) }}>
        <VisibilityIcon />
      </IconButton>
    }
    {value.bookedPeople === 0 &&
      <IconButton style={{ textDecoration: 'underline',  color: '#aaa', cursor: 'not-allowed'}}>
        <VisibilityOffIcon />
      </IconButton>
    }
  </span>,
  }
}*/

function timeSlotEditColumn(toggleSelectActivityTimeSlot: (activityTimeSlot: CRM.ActivityTimeSlotDetailed) => void, auth: AuthContext): Column<CRM.ActivityTimeSlotDetailed> {
  return {
  key: 'editTimeSlot',
  label: 'Modifier',
  order: 10,
  xs: 1,
  sortable: false,
  renderCell: value => <span>
    {value.domain.valueOf() === auth.userGroup?.domainId.valueOf() &&
      <IconButton style={{ fontSize: '1.8rem !important', padding:'0rem 0.5rem 0rem' }} onClick={(e) => { e.stopPropagation(); toggleSelectActivityTimeSlot(value); }}>
        <EditIcon />
      </IconButton>
    }
    {value.domain.valueOf() !== auth.userGroup?.domainId.valueOf() &&
      <IconButton 
      style={{ color: '#aaa', fontSize: '1.8rem !important', cursor:'not-allowed', padding:'0rem 0.5rem 0rem' }}
      onClick={(e) => { e.stopPropagation();}}
      >
        <BlockIcon />
      </IconButton>
    }
  </span>,
  }
}

function timeSlotDeleteColumn(toggleSelectActivityTimeSlot: (activityTimeSlot: CRM.ActivityTimeSlotDetailed) => void, deletetimeSlotDialog: () => void, domainId: string): Column<CRM.ActivityTimeSlotDetailed> {

  return {
  key: 'delete',
  label: 'Supprimer',
  order: 11,
  xs: 1,
  sortable: false,
  renderCell: value => <span>
  {
    (value.bookedPeople + value.bookedPeopleWithMonitor === 0 && (value.domain.valueOf() === domainId.valueOf())) && 
    <IconButton
            title={'supprimer'}
            onClick={(e) => {e.stopPropagation();toggleSelectActivityTimeSlot(value);deletetimeSlotDialog();}}
            style={{fontSize: '1.8rem !important', padding:'0rem 0.5rem 0rem'}}
          >
            <DeleteIcon />
    </IconButton>
  }
  {
    ((value.bookedPeople + value.bookedPeopleWithMonitor > 0) || (value.domain.valueOf() !== domainId.valueOf())) && 
    <IconButton
            title={'supprimer'}
            style={{fontSize: '1.8rem !important', padding:'0rem 0.5rem 0rem', color: '#aaa', cursor: 'not-allowed'}}
            onClick={(e) => {e.stopPropagation();}}
          >
            <DeleteForeverIcon />
    </IconButton>
  }
  </span>}
};

const createNewTimeSlotColumn: Column<CRM.ActivityTimeSlotDetailed> = {
  key: 'createNewTimeSlot',
  label: 'Créer résa',
  order: 11,
  xs: 1,
  sortable: false,
  renderCell: value => <span>
    {
      value.closeReservations !== true && (value.bookedPeople) < (value.maxPeople - 1) &&
      <Link to={"/reservations/create?activityid=" + value.activity._id + "&timeslotid=" + value._id}>
        <AddIcon />
      </Link>
    }
    {
      (value.closeReservations === true || ((value.bookedPeople) >= (value.maxPeople - 1))) &&
      <Link to="#" style={{color: 'InactiveCaption'}}>
        <EnhancedEncryptionIcon />
      </Link>
    }
  </span>,
};

export const activityTimeSlotsTableProvider: ActivityTimeSlotsTableProvider = 
(
  domainId: string,
  //reservationsViewDialog: (activity?:string, id?: string) => void,
  toggleSelectActivityTimeSlot: (activityTimeSlot: CRM.ActivityTimeSlotDetailed) => void,
  auth: AuthContext,
  domains: CRM.EntityName[],
  showCreateTimeSlotColumn: boolean,
  deletetimeSlotDialog?: () => void,
) => {
  const columns: Column<CRM.ActivityTimeSlotDetailed>[] = [
    timeSlotActivityColumn,
    timeSlotDomainColumn(domains),
    timeslotDateColumn,
    timeSlotDepartureHourColumn,
    timeSlotArrivalHourColumn,
    timeSlotPeopleColumn(),
    timeSlotPeopleWithMonitorColumn(),
    timeSlotMonitorColumn,
    timeSlotAvailabilityColumn,
  ];
  if(auth.userGroup?.role === 'admin') {
    columns.push(timeSlotEditColumn(toggleSelectActivityTimeSlot, auth));
  }
  if(showCreateTimeSlotColumn) {
    columns.push(createNewTimeSlotColumn);
  }
  if(deletetimeSlotDialog) {
    columns.push(timeSlotDeleteColumn(toggleSelectActivityTimeSlot, deletetimeSlotDialog, domainId));
  }
  return {
    columns
  };
}

export const availableActivityTimeSlotsTableProvider: AvailableActivityTimeSlotsTableProvider = 
() => {
  return {
    columns: [
      timeSlotActivityColumn,
      timeslotDateColumn,
      timeSlotDepartureHourColumn,
      timeSlotArrivalHourColumn,
      timeSlotPeopleColumn(),
      timeSlotPeopleWithMonitorColumn(),
      timeSlotMonitorColumn,
      timeSlotAvailabilityColumn,
    ],
  };
}



