import React, { useCallback, useEffect, useState } from 'react';
import { departureCapacityColor, fixedDayjs } from '@crm/utils';
import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from 'dayjs';
import { get } from '../../../fetch';
import { useDomainPath } from '../../../auth/auth.context';
import { TextPaper } from '../../../components/TextPaper.component';
import { useReservationActivity } from '../reservation.context';
import { CheckBusReplace } from './checkBusReplace.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrival: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: '1px solid',
      borderColor: theme.palette.text.disabled,
      borderRadius: 5,
      cursor: 'pointer',
      padding: '5px',
      '&:hover': {
        boxShadow: theme.shadows['3'],
      },
    },
    arrivalSelected: {
      backgroundColor: 'white',
      boxShadow: theme.shadows['3'],
    },
    arrivalDisabled: {
      cursor: 'default',
      backgroundColor: theme.palette.text.disabled,
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
);

interface Props {
  activity: CRM.PublicActivity;
  activityDate: Dayjs;
  reservation:Partial<CRM.Reservation>;
  allSpots: CRM.ActivitySpotDocument[];
  value?: Date;
  /*isPendingModification: boolean;*/
  onChange: (date: Dayjs, color: string) => void;
  onCheckBusUpdate:() => void;
  lastSaveDate?: Date;
}

export const ArrivalHourSelect: React.FC<Props> = ({
  activity,
  onChange,
  onCheckBusUpdate,
  activityDate,
  reservation,
  allSpots,
  value,
  /*isPendingModification,*/
  lastSaveDate
}: Props) => {
  const { activityTimeSlot } = useReservationActivity();
  const classes = useStyles();
  const arrivalsPath = useDomainPath('/arrivals/activities');
  const [loading, setLoading] = useState(false);
  const reservationPath = useDomainPath('/reservation');
  const [spotTimingOccurence, setSpotTimingOccurence] = useState<CRM.SpotTimingsOccurence>(
    {
      hour: 0, 
      minute: 0, 
      capacity: 0, 
      allowOverBooking: false
    }
  );
  /*const [isHourChanged, setIsHourChanged] = useState<boolean>(false);*/
  const [
    displayingArrivalsOfDay,
    setDisplayingArrivalsOfDay,
  ] = useState<Dayjs>();
  const [dayArrivals, setDayArrivals] = useState<
    CRM.ActivityOccurrence[] | undefined
  >();
  const [busesFilled, setBusesFilled] = useState<CRM.FilledBusTravel[]>([]);

  function onLoad() {
    console.log('loaded!!!!')
  }

  const refreshBusesFilled = useCallback(() =>{
    if (activity.spots.arrivalId && value) {
      const valueDayjs = fixedDayjs(value)
      get<CRM.FilledBusTravel[]>(reservationPath + `/travelReservations/filledBusesTravels?` +
        'day=' + valueDayjs.format('YYYYMMDD') +
        '&spotId=' + activity.spots.arrivalId +
        '&spotHour=' + valueDayjs.hour() +
        '&spotMinutes=' + valueDayjs.minute() +
        "&direction=" + ("return" as CRM.BusTravelDirection) +
        "&realTime=true")
        .then((buses) => {
          setBusesFilled(buses.sort().sort((a, b) =>
            ((b.bus.passengerCapacity - b.passengersCount) < (a.bus.passengerCapacity - a.passengersCount) ? -1 : 1)
          ));
          setSpotTimingOccurence({
            ...spotTimingOccurence,
            hour: valueDayjs.hour(),
            minute: valueDayjs.minute()
          });
        }
        );
    }
  }, [
    setBusesFilled, 
    reservationPath,
    value,
    activity.spots.arrivalId,
    spotTimingOccurence
  ]);

  const fetchDayArrivals = useCallback(() => {
    setLoading(true);
    const source: CRM.requestSource = 'backoffice';
    const dateFormated = displayingArrivalsOfDay
      ?.add(activity.overDays - 1, 'day')
      .format('YYYYMMDD');
    get<CRM.ActivityOccurrence[]>(
      `${arrivalsPath}/${activity!.id}?day=${dateFormated}&source=${source}`,
    ).then(res => {
      setLoading(false);
      let initArrival = res?.find(
        d => value && dayjs.utc(d.date, 'YYYYMMDDHHmm').isSame(value, 'minute')
      );
      // console.log(activityTimeSlot)
      if(activityTimeSlot) {
        res = res?.filter(
          h => dayjs(h.date).isSame(
            dayjs(activityTimeSlot.timeSlotArrival), 'hour'
          ) && dayjs(h.date).isSame(
            dayjs(activityTimeSlot.timeSlotArrival), 'minute'
          ));
          if(!value) {
            initArrival = res?.find(
              d => dayjs.utc(d.date, 'YYYYMMDDHHmm').isSame(dayjs(activityTimeSlot.timeSlotArrival), 'minute')
            );
          }
      }
      setDayArrivals(res);
      refreshBusesFilled();
      if(initArrival !== undefined) {
        onChange(dayjs.utc(initArrival.date, 'YYYYMMDDHHmm'), departureCapacityColor(initArrival));
      }

    });
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, arrivalsPath, displayingArrivalsOfDay, activityTimeSlot]);

  useEffect(() => {
    if (
      activityDate &&
      displayingArrivalsOfDay &&
      activityDate.isSame(displayingArrivalsOfDay, 'day')
    ) {
      return;
    }
    setDisplayingArrivalsOfDay(activityDate);
  }, [activityDate, displayingArrivalsOfDay]);

  /*useEffect(() => {
    if(!isPendingModification) {//means not modified or has just saved now
      setIsHourChanged(false);
    }
  }, [isPendingModification]);*/

  useEffect(fetchDayArrivals, [
    fetchDayArrivals,
    lastSaveDate, // force reload to have the right occupation
    activityTimeSlot?.timeSlotArrival // force reload to have the right time labels
  ]);

  if (loading) {
    return (
      <Box>
        <CircularProgress size="1em" />
      </Box>
    );
  }

  if (activity && dayArrivals?.length === 0) {
    return <span>Désolé, aucun horaire n'est disponible</span>;
  }

  return (
    <>
    {
    reservation.checkOutBusId && 
    (reservation.status === "done" || reservation.status === "finalize") &&
      <Grid container 
      xs={12} 
      style={{marginBottom:'1em'}} 
      justify="flex-end"
      alignItems="center">
        <Grid item xs={4}>
          Bus: {reservation.checkOutBusId && busesFilled.find(bt => bt.bus._id.includes(reservation.checkOutBusId || ""))?.bus.name}
        </Grid>
        <Grid item xs={8} style={{ textAlign: "right" }}>
          <CheckBusReplace
            busesFilled={busesFilled}
            reservation={reservation}
            spotTimingOccurence={spotTimingOccurence}
            allSpots={allSpots}
            spotId={activity.spots.arrivalId}
            direction='return'
            onUpdate={() => { onCheckBusUpdate(); }}
          />
        </Grid>
      </Grid>
    }
    <TextPaper>
      <Grid container spacing={1}>
        {dayArrivals
          ?.map(arrival => {
            const arrivalDateTime = dayjs.utc(arrival.date, 'YYYYMMDDHHmm');
            return {
              ...arrival,
              color: departureCapacityColor(arrival),
              date: arrivalDateTime.local(),
              placesRemaining: arrival.maxPeople - arrival.currentPeople,
              isSelected: value && arrivalDateTime.isSame(value),
              isBeforeActivity:
                !activityDate ||
                arrivalDateTime.isBefore(activityDate) ||
                arrivalDateTime.isSame(activityDate),
            };
          })
          .map(arrival => {
            return (
            <Grid item xs={6} key={`${activity.id}-${arrival.date}`}>
              <Box
                className={[
                  classes.arrival,
                  arrival.isBeforeActivity ? classes.arrivalDisabled : '',
                  arrival.isSelected ? classes.arrivalSelected : '',
                ].join(' ')}
                onLoad={onLoad}
                onClick={() => {
                  if (!arrival.isBeforeActivity && !arrival.isSelected) {
                    // setIsHourChanged(true);
                    onChange(arrival.date, departureCapacityColor(arrival));
                  }
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box fontWeight={'bold'} fontSize={'1rem'} color={
                      arrival.color
                    }>
                      {arrival.date.format('HH[h]mm')}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                    fontSize={'0.65rem'}
                    color={arrival.color}
                    style={{textAlign: 'right'}}
                    >
                      {arrival.maxPeople - arrival.placesRemaining} places occupées
                    </Box>
                  </Grid>
                </Grid>
                <Typography variant={'caption'}>
                  {arrival.placesRemaining} places restantes
                </Typography>
              </Box>
            </Grid>
          )
      })}
      </Grid>
    </TextPaper>
    </>
  );
};
