import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDomainPath } from "../auth/auth.context";
import { useAlerts } from "../hooks/useAlerts";
import { Box, Button, Checkbox, Chip, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { FormTitle } from "../components/FormTitle.component";
import React from "react";
import { UserSelect } from "../components/UserSelect.component";
import { SpotSelect } from "../components/SpotSelect.components";
import AddIcon from '@material-ui/icons/Add';
import { SpotMultiTimingsFilter } from "../components/filters/spotTimingsFilter.component";
import { get, post, put } from "../fetch";
import { useDomains } from "../hooks/useDomain";
import { keyBy } from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AssignVehicleFormProps {
  busId: string;
  travelDirection: CRM.BusTravelDirection;
  onCompletedValidationForm: () => void;
}

export const AssignVehicleForm: React.FC<AssignVehicleFormProps> = ({
  busId,
  onCompletedValidationForm,
  travelDirection
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const [spotToAdd, setSpotToAdd] = useState<CRM.ActivitySpotDocument | undefined>(undefined);
  const path = useDomainPath(`/busTravel`);
  const userPath = useDomainPath(`/user`);
  const spotsPath = useDomainPath('/activity-spot');
  const { notify } = useAlerts();
  const defaultBusTravel: Partial<CRM.BusTravelDocument> = { 
    allowedDomains: [], 
    travelSpotsTimings:[], 
    direction: travelDirection,
    bus: busId 
  };
  const [busTravelUpdate, setBusTravelUpdate] = useState<Partial<CRM.BusTravelDocument>>(defaultBusTravel);
  //const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const [users, setUsers] = useState<CRM.UserDocument[]>([]);
  const domains = useDomains();
  const domainById = keyBy(domains, domain => domain._id);
  
  const assignChange = useCallback(() => {
    const cannotSaveMessages = [];
    if (busTravelUpdate.allowedDomains?.length === 0) {
      cannotSaveMessages.push('Sélectionnez une où plusieurs entités');
    }
    /*if (busTravelUpdate.driverUserId === undefined) {
      cannotSaveMessages.push('Sélectionnez un chauffeur');
    }*/
    setErrors(cannotSaveMessages);
  }, [busTravelUpdate]);
  useEffect(assignChange, [assignChange, busTravelUpdate]);

  /*useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setDomains]);*/

  useEffect(() => {
    get<CRM.UserDocument[]>(userPath + `/drivers`).then(users => {
      setUsers(users);
    });
  }, [setUsers, userPath]);

  useEffect(() => {
    get<CRM.ActivitySpotDocument[]>(spotsPath).then(setSpots);
  }, [setSpots, spotsPath]);

  useEffect(() => {
    if (travelDirection === "departure") {
      get<CRM.BusTravelDocument>(path + `/departureForBusId/` + busId).then(
        (_busTravel) => {
          if(_busTravel) {
            setBusTravelUpdate(_busTravel);
          }
        }
      );
    } else if (travelDirection === "return"){
      get<CRM.BusTravelDocument>(path + `/arrivalForBusId/` + busId).then(
        (_busTravel) => {
          if(_busTravel) {
            setBusTravelUpdate(_busTravel);
          }
        }
      );
    }
  }, [path, busId, travelDirection]);
    

  const validateAssignVehicle = useCallback(async (_busTravel: Partial<CRM.BusTravelDocument>) => {
    if (_busTravel._id) {
      try {
        await put<CRM.BusTravelDocument>(path, _busTravel as CRM.BusTravelDocument);
        notify(`Attribution mise à jour avec succès`, 'success');
        //dialogClose();
        //onCompletedValidationForm();
      } catch (err) {
        notify(`Impossible d'attribuer le véhicule`, 'error');
      }
    } else {
      try {
        const busTravel = await post<Partial<CRM.BusTravelDocument>>(path, _busTravel);
        notify(`Attribution créée avec succès`, 'success');
        setBusTravelUpdate(busTravel);
        //dialogClose();
        //onCompletedValidationForm();
      } catch (err) {
        notify(`Impossible d'attribuer le véhicule`, 'error');
      }
    }
  },
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return(
    <Box className='App' display="flex">
    <Grid container spacing={3} xs={12}>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={12}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <FormTitle>Entités</FormTitle>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="Departures-select-label">Entités</InputLabel>
            <Select
              multiple
              label="Entités"
              labelId="Domains-select-label"
              value={domains.map((d) => {
                if (busTravelUpdate.allowedDomains && busTravelUpdate.allowedDomains.includes(d._id)) {
                  return d._id;
                }
                return undefined;
              })}
              renderValue={selected => {
                return (selected as string[])
                  .filter(selectedDomain => domainById[selectedDomain])
                  .map(selectedDomain => domainById[selectedDomain].displayName)
                  .join(', ');
              }}
              onChange={(event: ChangeEvent<{ checked?: boolean; value: any }>) => {
                const allowedDomains: string[] = event?.target.value.filter((v: string) => v !== undefined);
                setBusTravelUpdate(
                  {
                    ...busTravelUpdate,
                    allowedDomains: allowedDomains
                  });
                //console.log(event?.target.value, allowedDomains, busTravelUpdate.allowedDomains)
              }}
              MenuProps={MenuProps}
            >
              {domains.map((d) => (
                <MenuItem key={d._id} value={d._id}>
                  <Checkbox checked={
                    busTravelUpdate.allowedDomains !== undefined && 
                    busTravelUpdate.allowedDomains.find(_d => _d.includes(d._id)) !== undefined
                  } />
                  <ListItemText primary={d.displayName} />
                </MenuItem>
              )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <UserSelect
            users={users}
            onChange={(userId) => {
              setBusTravelUpdate(
                {
                  ...busTravelUpdate,
                  driverUserId: userId
                });
            }
            }
            value={busTravelUpdate.driverUserId}
            label="Chauffeur"
            allowUnselect={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTitle>Spots</FormTitle>
          <Box display="flex" mt={2} mb={2}>
          <Grid container xs={12} spacing={3}>
            <Grid item xs={6}>
              { busTravelUpdate.direction === "departure" && (
                <SpotSelect
                  onlyDepartures
                  label={'Départ'}
                  value={spotToAdd?._id}
                  spots={spots.filter(s => !busTravelUpdate.travelSpotsTimings?.find(st => st.spotId.includes(s._id)))}
                  onChange={spot =>
                    setSpotToAdd(spot)
                  }
                />
              )
              }
              { busTravelUpdate.direction === "return" && (
                <SpotSelect
                  onlyArrivals
                  label={'Retour'}
                  value={spotToAdd?._id}
                  spots={spots.filter(s => !busTravelUpdate.travelSpotsTimings?.find(st => st.spotId.includes(s._id)))}
                  onChange={spot =>
                    setSpotToAdd(spot)
                  }
                />
              )
              }
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                disabled={spotToAdd ? false : true}
                startIcon={<AddIcon />}
                onClick={() => {
                  if(spotToAdd && 
                    !busTravelUpdate.travelSpotsTimings?.find(s => s.spotId === spotToAdd._id)
                    ) {
                      console.log(busTravelUpdate.travelSpotsTimings?.concat([{
                        spotId: spotToAdd._id, 
                        timings: spots.find(s => s._id === spotToAdd._id)?.departures?.every || []
                      }]));
                      setBusTravelUpdate({
                        ...busTravelUpdate,
                        travelSpotsTimings: busTravelUpdate.travelSpotsTimings?.concat([{
                          spotId: spotToAdd._id, 
                          timings: spots.find(s => s._id === spotToAdd._id)?.departures?.every || []
                        }])
                    });
                  }
                }
                }
              >
                Ajouter
              </Button>
            </Grid>
          </Grid>
          </Box>
          <Box display="flex" mt={2} mb={2}>
          <Grid container xs={12} spacing={2} direction={'row'}>
                {busTravelUpdate.travelSpotsTimings?.map((spotTiming) => {
                  const timings = travelDirection === "departure" ? spots.find(s => s._id === spotTiming.spotId)?.departures :
                  spots.find(s => s._id === spotTiming.spotId)?.arrivals;
                  return (<Grid item xs={6}>
                    <Chip
                      key={spotTiming.spotId}
                      label={spots.find(s=> s._id.includes(spotTiming.spotId))?.displayName}
                      style={{marginBottom:'0.5rem'}}
                    />
                    <SpotMultiTimingsFilter
                      timings={
                        timings || { every: [] }
                      }
                      includingOccurrences={spotTiming.timings}
                      onChange={(_timings) => {

                        setBusTravelUpdate(
                          {
                            ...busTravelUpdate,
                            travelSpotsTimings:
                              busTravelUpdate.travelSpotsTimings?.filter(
                                s => s.spotId !== spotTiming.spotId
                              ).concat([{ spotId: spotTiming.spotId, timings: _timings }])
                          });
                      }}
                    />
                  </Grid>);
                })}
            </Grid>
            </Box>
            <Grid container xs={12} spacing={3}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                disabled={errors.length === 0 ? false : true}
                type="submit"
                onMouseDown={e => {
                  validateAssignVehicle(busTravelUpdate);
                }
                }
              >
                Sauvegarder
              </Button>
              </Grid>
              <Grid item>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                onMouseDown={e => {
                  onCompletedValidationForm();
                }
                }
              >
                Fermer
              </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <b style={{ color: 'red' }}>{errors.map(e => { return e + ', ' })}</b>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </Box>
  );
}