import React, { ChangeEvent, useCallback } from 'react';
import { Box, Divider, Grid, TextField, Typography } from '@material-ui/core';
import {
  useDomain,
  useDomainChangeEventUpdate,
  useDomainValueUpdate,
} from './domain.context';
import { DomainSaveButton } from './saveButton.component';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MailIcon from '@material-ui/icons/Mail';
import { DocumentsConfigForm } from './documentsConfig.form';

export const DomainForm: React.FC = () => {
  const { value, save } = useDomain();
  const nameUpdater = useDomainChangeEventUpdate('name');
  const marketUrlUpdater = useDomainChangeEventUpdate('marketUrl');
  const websiteUrlUpdater = useDomainChangeEventUpdate('websiteUrl');
  const marketAfterPurchaseUrlUpdater = useDomainChangeEventUpdate(
    'marketAfterPurchaseUrl',
  );
  const docusignImpresonatedGuidUpdater = useDomainChangeEventUpdate(
    'docusignImpresonatedGuid',
  );
  const abbreviationUpdater = useDomainChangeEventUpdate('abbreviation');
  const mailingUpdater = useDomainValueUpdate('mailing');

  const updateSmtpHost = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      mailingUpdater({
        ...value.mailing,
        smtp: { ...value.mailing?.smtp, host: e.target.value },
      });
    },
    [value.mailing, mailingUpdater],
  );
  const updateSmtpUser = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      mailingUpdater({
        ...value.mailing,
        smtp: { ...value.mailing?.smtp, username: e.target.value },
      });
    },
    [value.mailing, mailingUpdater],
  );
  const updateSmtpPassword = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      mailingUpdater({
        ...value.mailing,
        smtp: { ...value.mailing?.smtp, password: e.target.value },
      });
    },
    [value.mailing, mailingUpdater],
  );

  return (
    <form onSubmit={save}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <TextField
            label="Nom interne au CRM"
            variant="outlined"
            value={value.name}
            onChange={nameUpdater}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Abréviation"
            variant="outlined"
            value={value.abbreviation}
            onChange={abbreviationUpdater}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Nom de domaine"
            variant="outlined"
            value={value.marketUrl}
            onChange={marketUrlUpdater}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Identifiant Docusign"
            variant="outlined"
            value={value.docusignImpresonatedGuid}
            onChange={docusignImpresonatedGuidUpdater}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Site web"
            variant="outlined"
            value={value.websiteUrl}
            onChange={websiteUrlUpdater}
            required
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Page de remerciements"
            variant="outlined"
            value={value.marketAfterPurchaseUrl}
            onChange={marketAfterPurchaseUrlUpdater}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <AssignmentIcon /> Contrats
            </Typography>
          </Box>
        </Grid>
        <DocumentsConfigForm />
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <MailIcon /> Email
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Hôte"
            variant="outlined"
            value={value.mailing?.smtp.host || ''}
            onChange={updateSmtpHost}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Identifiant"
            variant="outlined"
            value={value.mailing?.smtp.username || ''}
            onChange={updateSmtpUser}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="Mot de passe"
            variant="outlined"
            value={value.mailing?.smtp.password || ''}
            onChange={updateSmtpPassword}
            type={'password'}
          />
        </Grid>
        <Grid item xs={12}>
          <DomainSaveButton />
        </Grid>
      </Grid>
    </form>
  );
};
