import React from 'react';
import { Box, Chip, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

interface SpotStripProps {
  selectedId: string;
  spots: CRM.ActivitySpotDocument[];
  onChange: (value: CRM.ActivitySpotDocument) => any;
}

const useStyles = makeStyles((theme: Theme) => ({
  activity: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    marginBottom: '0.3rem',
  },
  selected: {
    textDecoration: 'underline',
    backgroundColor: '#D5DADF',
  },
}));

export function SpotStrip({ onChange, spots, selectedId }: SpotStripProps) {
  const classes = useStyles();

  return (
    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
      {spots.map(spot => (
        <Chip
          className={clsx(classes.activity, {
            [classes.selected]: spot._id === selectedId,
          })}
          key={spot._id}
          label={spot.displayName}
          onClick={() => onChange(spot)}
        />
      ))}
    </Box>
  );
}
