import { Divider, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { TabPanel } from "../components/TabPanel.component";
import { TravelList } from "./travelList.component";

export function DriverTravelList() {
  const [selectedTab, setSelectedTab] = React.useState('departures');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label=""
        >
          <Tab value="departures" label="Départs" />
          <Tab value="arrivals" label="Retours" />
        </Tabs>
        <Divider variant="middle" />
        <TabPanel value={selectedTab} index="departures">
          <TravelList
            travelDirection="departure"
          />
        </TabPanel>
        <TabPanel value={selectedTab} index="arrivals">
          <TravelList
            travelDirection="return"
          />
        </TabPanel>
    </div>
  );
}
