import PeopleIcon from '@material-ui/icons/People';
import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import { countBookingPeople } from '@crm/utils';

export const ReservationPeopleColumn: React.FC<{
  people: CRM.BookingPeople;
  instructors?: string[];
  haveInstructor?: boolean;
  countInstructors?: boolean;
  _xs?: GridSize;
}> = ({ people, instructors, haveInstructor, countInstructors, _xs }) => (
  /*<Box display="flex" alignItems="end">
    {countBookingPeople(people, instructors)}&nbsp;
    {!haveInstructor &&
      <PeopleIcon />
    }
    {haveInstructor && haveInstructor === true &&
      (
      <Box>
        <PeopleIcon style={{ border: '1px solid currentColor' }} />
        <label>{instructors?.map((instructor) => instructor).join(', ')}</label>
      </Box>
      )
    }
  </Box>*/
  <Grid container spacing={1} xs={12}>
    <Grid container alignItems='center' xs={_xs? _xs : 6}>
      <span>
       { countBookingPeople(people, (countInstructors && countInstructors === true) ? instructors : undefined)}&nbsp;
      </span>
    {!haveInstructor &&
        <PeopleIcon />
    }
    {haveInstructor && haveInstructor === true &&
      (
        <PeopleIcon style={{ border: '1px solid currentColor' }} />
      )
    }
  </Grid>
    {haveInstructor && haveInstructor === true &&
        (
      <Grid
        title={'Monit : '+instructors?.map((instructor) => instructor).join(', ')}
        item
        xs={_xs? _xs : 6}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {instructors?.map((instructor) => instructor).join(', ')}
      </Grid>
      )
    }
  </Grid>
);
export const ReservationArrivalPeopleColumn: React.FC<{
  people: CRM.BookingPeople;
  peopleAtArrival?: number | null;
  instructors?: string[];
  haveInstructor?: boolean;
}> = ({ people, peopleAtArrival, instructors, haveInstructor }) => (
  <Grid container spacing={1} xs={12}>
    <Grid container alignItems='center' xs={6}>
    {(peopleAtArrival !== null
      && peopleAtArrival !== undefined
      && peopleAtArrival >= 0) ?
      peopleAtArrival : countBookingPeople(people, instructors)}&nbsp;
      {!haveInstructor &&
        <PeopleIcon />
      }
      {haveInstructor && haveInstructor === true &&
        <PeopleIcon style={{ border: '1px solid currentColor' }} />
      }
    </Grid>
    {haveInstructor && haveInstructor === true &&
        (
      <Grid
        title={'Monit : '+instructors?.map((instructor) => instructor).join(', ')}
        item
        xs={6}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {instructors?.map((instructor) => instructor).join(', ')}
      </Grid>
      )
    }
  </Grid>
);
