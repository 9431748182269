import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs, { Dayjs } from 'dayjs';
import { get } from '../../../fetch';
import { useDomainPath } from '../../../auth/auth.context';
import { TextPaper } from '../../../components/TextPaper.component';
import { DeparturesChoiceComponent } from './departuresChoice.component';
import { useLocation } from 'react-router-dom';
import { AvailableTimeSlotsDialog } from '../../../activities/create/timeslots/availabletimeSlotsDialog.component';
import { compareDate } from '../../../components/table/Table';
import { CheckBusReplace } from './checkBusReplace.component';
import { useReservation } from '../reservation.context';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { countBookingPeople } from '@crm/utils';

interface Props {
  reservation: Partial<CRM.ReservationDocument>;
  activity: CRM.PublicActivity;
  allSpots: CRM.ActivitySpotDocument[];
  activityTimeSlot?: CRM.ActivityTimeSlotDetailed;
  value?: Dayjs;
  lastSaveDate?: Date;
  isPendingModification?: boolean;
  onChange: (date: Dayjs) => void;
  onChangeTimeSlot: (id: string, monitor: string, activity: Partial<CRM.PublicActivity>) => void;
  onCheckBusUpdate:() => void;
}

export const DepartureSelect: React.FC<Props> = ({
  reservation,
  activity,
  allSpots,
  activityTimeSlot,
  onChange,
  onChangeTimeSlot,
  onCheckBusUpdate,
  value,
  lastSaveDate,
  // isPendingModification
}: Props) => {
  const schedulesPath = useDomainPath('/schedules');
  const reservationPath = useDomainPath('/reservation');
  const source: CRM.requestSource = 'backoffice';
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(value || dayjs());
  const [selectedDay, setSelectedDay] = useState<Dayjs>(value || dayjs().hour(0).minute(0));
  const [dayDepartures, setDayDepartures] = useState<
    CRM.ActivityOccurrence[] | undefined
  >();
  const { update } = useReservation();
  //const [isHourChanged, setIsHourChanged] = useState<boolean>(false);
  const [departureColor, setDepartureColor] = useState<string>('');
  const queryParameters = new URLSearchParams(useLocation().search);
  const timeSlotsPath = useDomainPath(`/activity-timeslots`);
  const [timeSlotsViewDialogOpen, setTimeSlotsViewDialogOpen] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<CRM.ActivityTimeSlotDetailed[]>([]);
  const [busesFilled, setBusesFilled] = useState<CRM.FilledBusTravel[]>([]);
  const [reservationBusFilled, setReservationBusFilled] = useState<CRM.FilledBusTravel | undefined>();
  const [spotTimingOccurence, setSpotTimingOccurence] = useState<CRM.SpotTimingsOccurence>(
    {
      hour: 0, 
      minute: 0, 
      capacity: 0, 
      allowOverBooking: false
    }
  );

  const refreshBusesFilled = useCallback((_selectedDay:Dayjs) =>{
    if (activity.spots.departureId) {
      get<CRM.FilledBusTravel[]>(reservationPath + `/travelReservations/filledBusesTravels?` +
        'day='+_selectedDay.format('YYYYMMDD') +
        '&spotId='+activity.spots.departureId +
        '&spotHour='+_selectedDay.hour() +
        '&spotMinutes='+_selectedDay.minute() +
        "&direction="+("departure" as CRM.BusTravelDirection) + 
        "&realTime=true")
        .then((buses) => {
          setBusesFilled(buses.sort().sort((a,b) => 
          ((b.bus.passengerCapacity - b.passengersCount) < (a.bus.passengerCapacity - a.passengersCount) ? -1 : 1)
          ));
        }
        );
        setSpotTimingOccurence({
          ...spotTimingOccurence, 
          hour: _selectedDay.hour(), 
          minute: _selectedDay.minute()
        });
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setBusesFilled, 
    reservationPath,
    activity.spots.departureId
  ]);

  useEffect(() => {
    if(reservation.checkInBusId && busesFilled.length > 0) {
      setReservationBusFilled(busesFilled.find(bt => bt.bus._id.includes(reservation.checkInBusId || "")))
    }
  },[
    busesFilled,
    reservation.checkInBusId
  ]);
  
  const timeSlotsViewDialogClose = useCallback(
    () => setTimeSlotsViewDialogOpen(false),
    [setTimeSlotsViewDialogOpen],
  );

  const timeSlotsViewDialog = useCallback(() => {
    if (activity.id) {
      get<CRM.ActivityTimeSlotDetailed[]>(`${timeSlotsPath}/all?fromDate=`+dayjs().tz('Europe/Paris').format('YYYYMMDD')).then((timeSlots) => {
        timeSlots = timeSlots.sort((a,b) => compareDate(a.timeSlotDeparture, b.timeSlotDeparture, false));

        setAvailableTimeSlots(timeSlots/*.filter(ts => ts.closeReservations !== true && ts.bookedPeople < (ts.maxPeople-1))*/);
        setTimeSlotsViewDialogOpen(true);
      }
      )
    }
  }
    , [
      setAvailableTimeSlots,
      setTimeSlotsViewDialogOpen,
      timeSlotsPath,
      activity.id
    ]
  );

  const fetchDayDepartures = useCallback(
    (date: Dayjs) => {
      const dateFormated = date.format('YYYYMMDD');
      get<CRM.ActivityOccurrence[]>(
        `${schedulesPath}/activities/${activity!.id}?date=${dateFormated}&source=${source}`,
      ).then(res => {
        if(activityTimeSlot) {
          res = res?.filter(
            h => dayjs(h.date).isSame(
              dayjs(activityTimeSlot.timeSlotDeparture), 'hour'
            ) && dayjs(h.date).isSame(
              dayjs(activityTimeSlot.timeSlotDeparture), 'minute'
            ));
        }
        setDayDepartures(res);
      });
    },
    [activity, schedulesPath, activityTimeSlot],
  );

  const changeHoursOfSelectedDay = useCallback((day:MaterialUiPickersDate) => {
    if(day?.isValid()) {
      setSelectedDay(day);
      refreshBusesFilled(day);
      onChange(day);
    }
  },[onChange, refreshBusesFilled]);

  useEffect(() => {
    if(activityTimeSlot) {//if timeSlot is selected, block all other hours and set the day
      fetchDayDepartures(dayjs(activityTimeSlot.timeSlotDeparture) || selectedMonth);
      changeHoursOfSelectedDay(dayjs(activityTimeSlot.timeSlotDeparture));
    } else {
      fetchDayDepartures(selectedMonth);
    }
    if(selectedDay.hour() !== 0) {
      refreshBusesFilled(selectedDay);
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDayDepartures,
    selectedMonth,
    lastSaveDate, // force reload to have the right occupation
  ]);


  /*useEffect(() => {
    if(!isPendingModification) {//means not modified or has just saved now
      setIsHourChanged(false);
    }
  }, [isPendingModification]);*/

  return (
    <>
      <Grid item xs={12}>
        <KeyboardDatePicker
          disableToolbar
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          label="À quelle date ?"
          disablePast={true}
          error={false}
          helperText={null}
          value={selectedDay}
          onMonthChange={date => {
            //setSelectedMonth(date!);
          }}
          disabled={activity.kind === 'supervised' ? true: false}
          onChange={(day) => {
            let newDate: MaterialUiPickersDate | undefined = day;
            setSelectedMonth(day!);
            /*console.log(selectedDay.hour())
            console.log(day?.hour())
            console.log(selectedDay.hour() !== day?.hour())*/

            //do keep hours if calendar is changed (uncomment for keep)
            /*if(selectedDay.hour() !== day?.hour()) {
              newDate = day?.add(
              selectedDay.hour(),
              'hour'
              )
              .add(
                selectedDay.minute(),
                'minute'
            );
            }*/
            //do reset hours if calendar changed (comment for keep)
            if(selectedDay.day() !== day?.day()) {
              newDate = day?.hour(0).minute(0);
            }

            if(day?.isValid()) {
              //setSelectedDay(newDate || dayjs()) /*as (date: MaterialUiPickersDate) => void*/
              changeHoursOfSelectedDay(newDate || dayjs());
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h6'}>
            Heure de départ:&nbsp;
            <b style={{color: departureColor}}>{((selectedDay.hour() !== 0) && selectedDay.format('HH[h]mm')) || (value && value.hour() !== 0 && value.format('HH[h]mm')) || '?'}</b>
            &nbsp;
            {activityTimeSlot && !queryParameters.has('timeslotid') &&
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={timeSlotsViewDialog}
              >Changer de Créneau</Button>
            }
        </Typography>
        {
        //reservation.checkInBusId && 
        ((reservation.people ? countBookingPeople(reservation.people) : 0) > 0) &&
        (
          reservation.status === "booked" || 
          reservation.status === "started" || 
          reservation.status === "in-activity"
        ) &&
          <Grid container xs={12}
          spacing={2}
          justify="flex-end"
          alignItems="center">
            { reservationBusFilled && reservation.checkInBusId &&
              <>
              <Grid item xs={5}>
                Bus: { reservationBusFilled.bus.name }
                <br/>
                <label style={{fontSize:'0.75rem'}}>
                { 
                '('+((reservationBusFilled.bus.passengerCapacity || 0) -
                (reservationBusFilled.passengersCount || 0)) +' places restantes)'
                }
                </label>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title={ 
                  (
                    reservation.checkInBusIdAutoFill === undefined || 
                    reservation.checkInBusIdAutoFill
                  ) ?"Supprimer et bloquer l'auto-remplissage" : "Supprimer"}>
                  <CancelIcon cursor={'pointer'} onClick={() => {
                    update({
                      checkInBusId: '',
                      checkInBusIdAutoFill: false
                    });
                  }
                  } />
                </Tooltip>
              </Grid>
              </>
            }
            {
              (((!reservation.checkInBusId && 
                (reservation.checkInBusIdAutoFill === undefined || reservation.checkInBusIdAutoFill === true))
                ) ||
              busesFilled.length === 0) &&
              <Grid item xs={6}
                spacing={2}
                justify="flex-end"
                alignItems="center" style={{fontSize:'0.75rem'}}>
                Aucun bus disponible avec suffisamment de capacité.
              </Grid>
            }
            { busesFilled.length > 0 &&
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <CheckBusReplace
                  busesFilled={busesFilled}
                  reservation={reservation}
                  spotTimingOccurence={spotTimingOccurence}
                  allSpots={allSpots}
                  spotId={activity.spots.departureId}
                  direction={'departure'}
                  onUpdate={onCheckBusUpdate}
                />
              </Grid>
            }
          </Grid>
        }
        <AvailableTimeSlotsDialog
          timeSlots={availableTimeSlots}
          dialogOpen={timeSlotsViewDialogOpen}
          dialogClose={timeSlotsViewDialogClose}
          onSelect={(id, monitor, activity)=>{onChangeTimeSlot(id, monitor, activity); timeSlotsViewDialogClose();}}
        />
      </Grid>
      {activity && (
        <Grid item xs={12}>
          <TextPaper>
            <DeparturesChoiceComponent
              dayDepartures={dayDepartures}
              value={selectedDay || value}
              selectedDay={selectedDay}
              /*overrideHourColor={(isHourChanged && isPendingModification) ? 'red' : undefined}*/
              onChange={(day) => {
                changeHoursOfSelectedDay(day);
                /*setIsHourChanged(true);*/
              }}
              onChangeColor={(color) => {
                setDepartureColor(color);
              }}
            />
          </TextPaper>
        </Grid>
      )}
    </>
  );
};
