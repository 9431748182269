import { Box, Button, CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { SpotStrip } from "../components/SpotStrip.component";
import { useAuth, useAuthUsers, useDomainPath } from "../auth/auth.context";
import { get, post } from "../fetch";
import { sortBy } from "lodash";
import { BusStatus } from "./components/busStatus.component";
import { SpotMultiTimingsFilter } from "../components/filters/spotTimingsFilter.component";
import { BusPrioritySelect } from "../components/selects/BusPrioritySelect.component";
import { fixedDayjs } from "@crm/utils";
import dayjs from "dayjs";
import { RichGrid } from "../components/table/RichGrid.component";
import { travelListPassengersTableProvider } from "../reservations/reservationGrid.columns";
import { RowHandlers } from "../components/table/Table";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

interface TravelListProps {
  travelDirection: CRM.BusTravelDirection;
}

export const TravelList: React.FC<TravelListProps> = ({
  travelDirection
}) => {
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const today: dayjs.Dayjs = fixedDayjs(Date()).add(0,'day');
  const [refresh, setRefresh] = useState<number>(1);
  const [refreshReservations, setRefreshReservations] = useState<number>(1);
  const [selectedSpot, setSelectedSpot] = useState<CRM.ActivitySpotDocument>();
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const spotPath = useDomainPath('/activity-spot');
  const busTravelPath = useDomainPath('/busTravel');
  const reservationPath = useDomainPath('/reservation');
  const { loggedUser } = useAuthUsers();
  const { userGroup } = useAuth();
  const [driverBusTravels, setDriverBusTravels] = useState<CRM.BusTravelDetailed[]>([]);
  const [viewPassengersReservations, setViewPassengersReservations] = useState<CRM.ReservationDetailed[]>([]);
  //const [selectedDriverBusTravel, setSelectedDriverBusTravel] = useState<CRM.BusTravelDetailed>();
  const [selectedTimingOcurrence, setSelectedTimingOcurrence] = useState<CRM.SpotTimingsOccurence>({
    hour:0, 
    minute: 0,
    capacity: 0,
    allowOverBooking: true,
  });
  const defaultPriorityBusTravel = {
    busTravelId: '',
    busId: '',
    busName: '',
    busStatus: "ready" as CRM.BusStatus,
    hour: 23,
    minute: 59
  }
  const [priorityBusTravel, setPriorityBusTravel] = useState<CRM.BusTravelPriorityParams>(
    defaultPriorityBusTravel
  );
  const [validateBusDepartureAction, setValidateBusDepartureAction] = useState<boolean>(false);
  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setDomains]);

  const inSpotIds = (ids: string[]) => {
    return (res: CRM.ActivitySpotDocument) => ids.length === 0 ? res : ids.includes(res._id);
  };

  const loadDriverBusTravels = useCallback(() => {
    const driverTravelsUri = travelDirection === "departure" ? 
      "/departuresForDriverId/"+loggedUser?._id : "/arrivalsForDriverId/"+loggedUser?._id;

    get<CRM.BusTravelDetailed[]>(busTravelPath+driverTravelsUri).then(result => {
      setDriverBusTravels(result);
    });
  },[travelDirection,loggedUser?._id, busTravelPath]);

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(spotPath).then(result => {
      const availableSpots = sortBy(
        result.filter(s =>
          travelDirection === "departure" ? 
            s.departures !== undefined && s.departures !== null && s.departures.every.length > 0 :
            s.arrivals !== undefined && s.arrivals !== null && s.arrivals.every.length > 0
        )
        .filter(inSpotIds(driverBusTravels.map(bt => bt.travelSpotsTimings.map(t => t.spotId)).flat())),
        spot => spot.displayName,
      );

      availableSpots.forEach((spot, index) => {
        const busTravelSpotTimings: CRM.SpotTimingsOccurence[] = ((driverBusTravels && spot) && driverBusTravels.map(
          (busTravel) => {
            return busTravel.travelSpotsTimings.find(ts => ts.spotId.includes(spot._id))?.timings || [];
          }
          ).flat()) || [];
          //console.log(busTravelSpotTimings);
          const spotTimingsUnique: CRM.SpotTimingsOccurence[] = [
            ...busTravelSpotTimings
              .reduce((spotTimingUnique, curr) => {
                //console.log(spotTimingUnique, ''+curr.hour+curr.minute)
                if (!spotTimingUnique.has(''+curr.hour+curr.minute)) {
                  spotTimingUnique.set(''+curr.hour+curr.minute, curr);
                }
                return spotTimingUnique;
              }, new Map())
              .values()
          ];
        if(travelDirection === "departure" && spot.departures) {
          availableSpots[index].departures = {
            every: spotTimingsUnique
          }
        } else if(travelDirection === "return" && spot.arrivals) {
          availableSpots[index].arrivals = {
            every: spotTimingsUnique
          }
        }
      });
      setSpots(availableSpots);
      //console.log(availableSpots[0])
      const updatedSpot = (selectedSpot && availableSpots.find(s => s._id.includes(selectedSpot._id))) || undefined;
      if (
          (
            (!selectedSpot && availableSpots.length > 0) || 
            ((selectedSpot && !availableSpots.map(s => s._id).includes(selectedSpot._id)) || updatedSpot))
          ) {
            //console.log(updatedSpot || availableSpots[0])
        changeSelectedSpot( updatedSpot || availableSpots[0]);
        setRefresh(refresh + 1);

        if(selectedTimingOcurrence.hour === 0 && selectedTimingOcurrence.minute === 0) {
          if(travelDirection === "departure") {
            setSelectedTimingOcurrence(
              updatedSpot?.departures?.every[0] || 
              availableSpots[0]?.departures?.every[0] ||
              selectedTimingOcurrence
            );
          } else {
            setSelectedTimingOcurrence(
              updatedSpot?.arrivals?.every[0] || 
              availableSpots[0]?.arrivals?.every[0] ||
              selectedTimingOcurrence
            );
          }
        }
      }
    });
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotPath, travelDirection, driverBusTravels]);

  useEffect(() => {
      if (
        priorityBusTravel.busTravelId === '' ||
        !selectedSpot ||
        selectedTimingOcurrence.hour === 0 ||
        !refreshReservations
      ) {
        return;
      }
      const busTravel = driverBusTravels.find(bt => priorityBusTravel.busTravelId.includes(bt._id));
      get<CRM.ReservationDetailed[]>(
        `${reservationPath}/travelReservations/filledOrEmptyBusReservations?` +
        'busId=' + (busTravel?.bus._id || '') + "&" +
        'day=' + fixedDayjs(Date()).add(0,'day').format('YYYYMMDD') +
        '&spotId=' + selectedSpot?._id +
        '&spotHour=' + selectedTimingOcurrence.hour +
        '&spotMinutes=' + selectedTimingOcurrence.minute +
        "&direction=" + travelDirection
      ).then((reservations) => {
        setViewPassengersReservations(reservations);
      }
      );
  },[
    priorityBusTravel,
    selectedSpot,
    selectedTimingOcurrence,
    travelDirection,
    reservationPath,
    driverBusTravels,
    refreshReservations
  ]);

  const changeSelectedSpot = useCallback((spot: CRM.ActivitySpotDocument) => {
    setSelectedSpot(spot);
  },[]);
  useEffect(loadDriverBusTravels, [loadDriverBusTravels]);
  useEffect(loadSpots, [loadSpots]);
  useEffect(() => {
    if (selectedSpot) {
      const driverBusTravel: CRM.BusTravelDetailed | undefined = driverBusTravels.find(
        bt => bt.travelSpotsTimings.find(ts => ts.spotId === selectedSpot._id &&
          ts.timings.find(
            t => t.hour === selectedTimingOcurrence.hour && t.minute === selectedTimingOcurrence.minute
          )
        ));
      if (driverBusTravel) {
        //setSelectedDriverBusTravel(driverBusTravel);
        setPriorityBusTravel({
          busTravelId: driverBusTravel._id,
          busId: driverBusTravel.bus._id,
          busName: driverBusTravel.bus.name,
          busStatus: driverBusTravel.bus.status,
          hour: selectedTimingOcurrence.hour,
          minute: selectedTimingOcurrence.minute,
        });
      }

    }
  }, [selectedTimingOcurrence, selectedSpot, driverBusTravels]);

  function checkEvent(reservationId: string, driverCheckStatus: CRM.ReservationDriverCheckStatus) {
    //#TODO update driverCheckStatus
    post<CRM.BusTravelFindParams>(
      reservationPath+'/travelReservations/driverCheck/'+reservationId+'/'+driverCheckStatus).then(() => {
        setRefreshReservations(refreshReservations+1);
      });
  }

  function updateTimingOcurrence(spot: CRM.ActivitySpotDocument) {
    setSelectedTimingOcurrence(
      travelDirection === "departure" ? (spot.departures?.every[0] || selectedTimingOcurrence) :
      (spot.arrivals?.every[0] || selectedTimingOcurrence)
    );
  }

  if (userGroup?.role !== "driver") {
    return <div><CircularProgress size="1em" /> Bientôt disponible pour les administrateurs</div>;
  }

  return (
    <Grid container xs={12} spacing={2}>
    <Grid item xs={2} sm={3} lg={3}>
      <SpotStrip
        selectedId={selectedSpot?._id || ''}
        onChange={(spot) => { changeSelectedSpot(spot); updateTimingOcurrence(spot);}}
        spots={spots}
      />
    </Grid>
    <Grid item xs={2} sm={2} lg={2}>
      <SpotMultiTimingsFilter
        timings={ (travelDirection === "departure" ? selectedSpot?.departures : selectedSpot?.arrivals) || {every:[]}}
        includingOccurrences={[selectedTimingOcurrence]}
        onChange={(timingOccurences) => {
          console.log(timingOccurences, selectedTimingOcurrence) 
          setSelectedTimingOcurrence(
            timingOccurences.find(
              o => o.hour+''+o.minute !== selectedTimingOcurrence.hour+''+selectedTimingOcurrence.minute
              ) || selectedTimingOcurrence
            )
        }}
      />
    </Grid>
    <Grid item xs={2} sm={3} lg={3}>
      <BusStatus
        busStatus={priorityBusTravel.busStatus}
      />
    </Grid>
      <Grid item xs={3} sm={4} lg={4} style={{height:'250px',overflowY:'scroll'}}>
        {selectedSpot && (
          <BusPrioritySelect
            spot={selectedSpot || spots[0]}
            direction={travelDirection}
            day={today}
            spotTimingOccurence={selectedTimingOcurrence}
            refresh={refresh}
            onUpdate={() => {setRefresh(refresh + 1); setRefreshReservations(refreshReservations+1)}}
            forcePriorityBusTravel={priorityBusTravel}
            setForcePriorityBusTravel={setPriorityBusTravel}
            forDriverId={loggedUser?._id}
            validateBusDepartureAction={validateBusDepartureAction}
            setValidateBusDepartureAction={setValidateBusDepartureAction}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <Box style={{ paddingBottom:'8vh', maxHeight: '70vh', overflow: 'hidden' }} className='App'>
          <Grid container spacing={2} xs={12} >
            <Grid item>
              <Typography variant="subtitle1">
                CLIENTS
              </Typography>
            </Grid>
          </Grid>
          <RichGrid
            style={{
              paddingRight: '10px', //compensate for scrollbar width
            }}
            columns={travelListPassengersTableProvider(domains, travelDirection, checkEvent).columns}
            rows={viewPassengersReservations}
            renderRow={reservation => {
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={classesgrid.root}
                    spacing={1}
                  >
                    {travelListPassengersTableProvider(domains, travelDirection, checkEvent).columns.map(column => {
                      const Cell = column.Cell;
                      return (
                        <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                          {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                          {column.renderCell &&
                            column.renderCell(reservation, {} as RowHandlers)}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              );
            }}
          />
        </Box>
        <Grid container spacing={2} xs={12} justify="center" >
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={priorityBusTravel.busStatus !== "ready" ? true : false}
              onMouseDown={e => { setValidateBusDepartureAction(true); }}
            > 
              Valider le départ du bus
            </Button>
          </Grid>
      </Grid>
  </Grid>
  );
}