import React from "react";
import { Column, Table, compareNumber } from "../components/table/Table";
import { Link } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';

const vehicleNameColumn: Column<CRM.Bus> = {
  key: 'vehicle',
  label: 'Nom',
  order: 2,
  xs: 2,
  sortable: true,
  compare: (a, b) => (a.name || '').localeCompare(b.name),
  renderCell: value => (
    <span>
      {value.name}
    </span>
  ),
};

const vehicleRegistrationColumn: Column<CRM.Bus> = {
  key: 'registration',
  label: 'Immat.',
  order: 4,
  xs: 1,
  sortable: true,
  compare: (a, b) => (a.registration || '').localeCompare(b.registration),
  renderCell: value => (
    <span>
      {value.registration}
    </span>
  ),
};

const vehicleFunctionalColumn: Column<CRM.Bus> = {
  key: 'functional',
  label: 'Fonctionnel',
  order: 5,
  xs: 1,
  sortable: true,
  compare: (a, b) => (String(a.functionnal) || '').localeCompare(String(b.functionnal)),
  renderCell: value => (
    <span>
      {value.functionnal && value.functionnal === true ? 'Oui' : 'Non'}
    </span>
  ),
};

function vehicleCapacityColumn(gridType: CRM.VehicleGridType, busToReplacePassengers: number[], placesRemaining?: number | undefined): Column<CRM.Bus> {
  return {
    key: 'capacity',
    label: 'Capacité max',
    order: 3,
    xs: 1,
    sortable: true,
    compare: (a, b) =>
      compareNumber(a.passengerCapacity, b.passengerCapacity),
    renderCell: value => {
      let capacityRemaining: number = value.passengerCapacity;
      let passengersToAllocate: number = 0;
       for(const count of busToReplacePassengers){
        if(capacityRemaining >= count) {
          capacityRemaining = capacityRemaining - count;
        } else {
          passengersToAllocate = passengersToAllocate + count;
        }
      }
      return (
      <div >
        {
        gridType === 'replace' && passengersToAllocate > 0 ?
        (<span title={(passengersToAllocate)+' personnes à répartir dans les autres bus'}
        >{value.passengerCapacity} <WarningIcon style={{verticalAlign:'middle', color:'orange'}}/>
        ({(passengersToAllocate)})
        </span>) : 
        (<span>
          {placesRemaining ? placesRemaining+' pl. restantes' : value.passengerCapacity} 
        </span>)
        }
      </div>
    )},
  }
};

function vehicleEditColumn(onConfigure: (bus: CRM.BusDocument) => void): Column<CRM.Bus> {
  return {
    key: 'edit',
    label: 'Modifier',
    order: 6,
    xs: 1,
    sortable: false,
    renderCell: value => (
      <span>
        <Link color="secondary" onClick={()=>{onConfigure(value)}} href="#">Configurer</Link>
      </span>
    ),
  }
};

function vehicleOtherSpotsOnTimingColumn(otherSpotsNames: string []): Column<CRM.Bus> {
  return {
    key: 'otherSpots',
    label: 'Spot(s) déjà sur ce créneau',
    order: 6,
    xs: 2,
    sortable: false,
    renderCell: () => (
      <div>
        {otherSpotsNames.length > 0 && (<span><WarningIcon style={{verticalAlign:'middle', color:'orange'}}/>&nbsp;</span>)}
        <span>{otherSpotsNames.join()}</span>
      </div>
    ),
  }
};

function vehicleAssignColumn(onAssign: (busId: string) => void): Column<CRM.Bus> {
  return {
    key: 'assign',
    label: 'Attribuer',
    order: 7,
    xs: 1,
    sortable: false,
    renderCell: value => (
      <span>
        <Link color="secondary" onClick={()=>{onAssign(value._id)}} href="#">Attribuer</Link>
      </span>
    ),
  }
};

function vehicleTravelSelectColumn(onSelect: (busTravelId: string) => void, gridType: CRM.VehicleGridType, busTravelId: string): Column<CRM.Bus> {
  return {
    key: 'select',
    label: gridType === 'add' ? 'Ajouter' : 'Remplacer',
    order: 7,
    xs: 2,
    sortable: false,
    renderCell: () => (
      <span>
        <Link color="secondary" onClick={()=>{onSelect(busTravelId)}} href="#">
        {gridType === 'add' ? 'Ajouter ce bus' : 'Remplacer par ce bus'}
        </Link>
      </span>
    ),
  }
};

type VehiclesTableProvider = (
  gridType: CRM.VehicleGridType,
  onAssign: (id: string)=> void,
  onConfigure?: (bus: CRM.BusDocument)=> void,
  busToReplacePassengers?: number[],
  busTravelId?: string,
  otherSpotsNamesOnTiming?: string[],
  placesRemaining?: number
  //domainId: string,
) => Table<CRM.Bus>;

export const vehiclesTableProvider: VehiclesTableProvider = 
(
  gridType,
  onAssign: (id: string)=> void,
  onConfigure?: (bus: CRM.BusDocument)=> void,
  busToReplacePassengers?,
  busTravelId?,
  otherSpotsNamesOnTiming?,
  placesRemaining?
) => {
  let columns: Column<CRM.Bus>[] = [];

  switch(gridType) {
    case 'admin': {
      columns = [
        vehicleNameColumn,
        vehicleCapacityColumn(gridType, busToReplacePassengers || [], placesRemaining),
        vehicleRegistrationColumn,
        vehicleFunctionalColumn,
        vehicleAssignColumn(onAssign)
      ];
      if(onConfigure) {
        columns.push(vehicleEditColumn(onConfigure));
      }
      break;
    }
    case 'add': {
      columns = [
        vehicleNameColumn,
        vehicleCapacityColumn(gridType, busToReplacePassengers || [], placesRemaining),
        vehicleOtherSpotsOnTimingColumn(otherSpotsNamesOnTiming || []),
        vehicleTravelSelectColumn(onAssign, 'add', busTravelId || '')
      ];
      break;
    }
    case 'replace': {
      columns = [
        vehicleNameColumn,
        vehicleCapacityColumn(gridType, busToReplacePassengers || [], placesRemaining),
        vehicleOtherSpotsOnTimingColumn(otherSpotsNamesOnTiming || []),
        vehicleTravelSelectColumn(onAssign, 'replace', busTravelId || '')
      ];
      break;
    }
  }
  return {
    columns
  };
}