import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { PositiveNumberField } from "../components/fields/PositiveNumberField.component";
import { useDomainPath } from "../auth/auth.context";
import { httpDelete, post, put } from "../fetch";
import { useAlerts } from "../hooks/useAlerts";


interface VehicleDialogProps {
  dialogOpen: boolean;
  dialogClose: any;
  bus: Partial<CRM.BusDocument>;
  busUpdate: React.Dispatch<React.SetStateAction<Partial<CRM.BusDocument>>>;
}

export const EditVehicleDialog: React.FC<VehicleDialogProps> = ({
  dialogOpen,
  dialogClose,
  bus,
  busUpdate
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const path = useDomainPath(`/bus`);
  const reservationPath = useDomainPath(`/reservation`);
  const { notify } = useAlerts();
  const [showDeleteWarningAlert, setShowDeleteWarningAlert] = useState<boolean>(false);

  const deleteVehicle = useCallback(async () => {
    let r: boolean;
    try {
      const res: string = await httpDelete(reservationPath+'/travelReservations/removeBus/'+bus._id);
      r = res === 'true' ? true : false;
    } catch(err) {
      r = false;
    }
    if(r && r === true) {
      notify(`Véhicule supprimé avec succès`, 'success');
      dialogClose();
    } else { notify(`Impossible de supprimer le Véhicule`, 'error'); }

  },[reservationPath, bus._id, dialogClose, notify]);

  const validateVehicle = useCallback(async (_bus: Partial<CRM.BusDocument>) => {
    if(_bus._id) {
      try {
        await put<CRM.BusDocument>(path, _bus as CRM.BusDocument);
        notify(`Véhicule mis à jour avec succès`, 'success');
        dialogClose();
      } catch (err) {
        notify(`Impossible de modifier le véhicule`, 'error');
      }
    } else {
      try {
        await post<Partial<CRM.BusDocument>>(path, _bus);
        notify(`Véhicule créé avec succès`, 'success');
        dialogClose();
      } catch (err) {
        notify(`Impossible de créer le véhicule`, 'error');
      }
    }
  }, 
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <Dialog
      onClose={dialogClose}
      open={dialogOpen}
      maxWidth={'sm'}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            Configurer le véhicule
          </Box>
          <Box>
            <IconButton onClick={dialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ minHeight: '280px' }}>
        <Box className='App' display="flex">
          <Grid container spacing={3} xs={12}>
            {!showDeleteWarningAlert &&
              <Grid
                container
                spacing={3}
                item
                xs={12}
                sm={12}
                alignContent={'flex-start'}
              >
                <Grid item xs={6}>
                  <TextField
                    label="Nom du Véhicule"
                    variant="outlined"
                    value={bus.name}
                    onChange={e => { busUpdate({ ...bus, name: e.target.value }) }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <PositiveNumberField
                    label="Capacité max"
                    variant="outlined"
                    value={bus.passengerCapacity}
                    onChange={e => { busUpdate({ ...bus, passengerCapacity: Number(e.target.value) }) }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Immatriculation"
                    variant="outlined"
                    value={bus.registration}
                    onChange={e => { busUpdate({ ...bus, registration: e.target.value }) }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <FormLabel id="functional">Fonctionnel</FormLabel>
                    <RadioGroup
                      aria-labelledby="Fonctionnel"
                      value={bus.functionnal === true ? 'true' : 'false'}
                      name="radio-buttons-group"
                      onChange={e => {
                        busUpdate({ ...bus, functionnal: e.target.value === 'true' ? true : false })
                      }
                      }
                    >
                      <FormControlLabel value='true' control={<Radio />} label="Oui" />
                      <FormControlLabel value='false' control={<Radio />} label="Non" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    onMouseDown={e => { validateVehicle(bus); }}
                  >
                    Sauvegarder
                  </Button>
                  &nbsp;
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onMouseDown={e => { setShowDeleteWarningAlert(true); }}
                  >
                    Supprimer
                  </Button>
                </Grid>
              </Grid>
            }
            {showDeleteWarningAlert &&
              <Grid
              container
              spacing={3}
              item
              xs={12}
              sm={12}
              alignContent={'flex-start'}
            >
              <Grid item xs={12}>
                <h3>Etes-vous certain de vouloir supprimer ce bus ? </h3>
                <p>
                  Tous ses passagers des réservations passées présentes déjà affectés se retrouveront sans bus.
                  <b>Cette action est irréversible</b>.
                  S'il y a des réservations en cours de location où retour,
                  vous devrez choisir un bus manuellement pour chacune d'entre elles.
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onMouseDown={e => { deleteVehicle(); setShowDeleteWarningAlert(false); }}
                >
                  Oui
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onMouseDown={e => { setShowDeleteWarningAlert(false); }}
                >
                  Non
                </Button>
              </Grid>
            </Grid>
          }
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}